import AnalysisApi, { RequestListParams } from 'api/AnalysisApi';
import DraftApi, { AnalysisDraft } from 'api/DraftApi';
import { Loading } from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounced';
import analysisTypeName from 'logic/analysis/analysisTypeName';
import { analysisUrl } from 'logic/analysis/analysisUtils';
import { useEffect, useMemo, useState } from 'react';
import Plus from 'react-feather/dist/icons/plus';
import Star from 'react-feather/dist/icons/star';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMenu } from 'reducers/menuReducer';
import { useSettings, useUser } from 'user';
import styled from 'styled-components';
import { Box, Button, Heading, Input, tombac, useToasts } from 'tombac';
import {
  DeleteIcon,
  DraftIcon,
  ReportIcon,
  SearchIcon,
  SpinnerIcon,
} from 'tombac-icons';
import {
  AnalysisInfo,
  AnalysisPage,
  AnalysisStatus,
} from '../../model/AnalysisDto';
import { formatDate } from '../AnalysisNew/DateRanges/DateUtils';
import Title from '../Layout/Title';
import { Delayed } from '../UI/Delayed';
import { Spacer } from '../UI/FormUI';
import AnalysisListItem from './AnalysisListItem';
import './AnalysisTable.css';
import { Pagination } from './Pagination';
import ReportsTrialBox from './ReportsTrialBox';
import { SimpleRadioGroup } from './SimpleRadioGroup';
import StatusFilter from './StatusFilter';
import { TableAnnouncement } from './TableAnnouncement';

function DraftItem({ draft }: { draft: AnalysisDraft }) {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const [, , { continueFromDraft }] = useMenu();
  const history = useHistory();
  const settings = useSettings();

  const restoreDraft = async () => {
    const fullDraft = await DraftApi.get(draft.id, draft.type);
    addToast('Restored from draft', 'success');
    continueFromDraft(fullDraft);
    history.push('/new/dates');
  };

  return (
    <div className="DraftItem" onClick={restoreDraft}>
      <div>
        <div className="DraftItem__type">{analysisTypeName(draft.type)}</div>
        <div className="DraftItem__name">{draft.name}</div>
      </div>
      <div className="DraftItem__date">
        {formatDate(draft.creationTime, settings?.dateFormat)}
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <Button
          size="s"
          shape="circle"
          $background="transparent"
          variant="flat"
          prepend={<DeleteIcon />}
          onClick={async () => {
            await DraftApi.deleteDraft(draft.id, queryClient);
            addToast(`Draft ${draft.name} deleted`);
          }}
        ></Button>
      </div>
    </div>
  );
}

function DraftsList() {
  const drafts = DraftApi.useDrafts();

  if (drafts === undefined) {
    return (
      <div className="AnalysisTable__content">
        <Loading />
      </div>
    );
  }

  if (drafts.length === 0) {
    return (
      <div className="AnalysisTable__content">
        Your drafts will appear here. Create drafts by clicking '
        <DraftIcon /> Save as Draft' button while creating your report
      </div>
    );
  }

  return (
    <div className="AnalysisTable__content">
      <Heading level={2} fontWeight="bold">
        Drafts
      </Heading>
      <div className="DraftsList__header">
        <div>Name</div>
        <div>Created on</div>
      </div>
      {drafts === undefined && <Loading />}
      {drafts !== undefined &&
        drafts.map((it) => <DraftItem draft={it} key={it.id} />)}
    </div>
  );
}

const MenuButton = styled.div<{ active?: boolean }>`
  width: 100%;
  height: 56px;
  padding: 0 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-left: 7px solid
    ${(p) => (p.active ? tombac.color('accent', 500) : 'transparent')};
  background: ${(p) => (p.active ? tombac.color('accent', 200) : '')};
  color: ${(p) => (p.active ? tombac.color('accent', 500) : '#333')};

  :hover {
    background: ${(p) => (p.active ? '' : tombac.color('neutral', 200))};
  }

  svg {
    margin-right: 8px;
  }
`;

export default function AnalysisTablePage() {
  const location = useLocation();
  const history = useHistory();
  const listType = location.pathname === '/dashboard' ? 'reports' : 'drafts';

  return (
    <div className="AnalysisTable__container">
      <div className="AnalysisTable__side-menu">
        <MenuButton
          onClick={() => history.push('/dashboard')}
          active={listType === 'reports'}
        >
          <ReportIcon />
          My reports
        </MenuButton>
        <MenuButton
          onClick={() => {
            history.push('/dashboard/drafts');
          }}
          active={listType === 'drafts'}
        >
          <DraftIcon />
          Drafts
        </MenuButton>
      </div>
      {listType === 'reports' && <AnalysisTable title="My reports" />}
      {listType === 'drafts' && <DraftsList />}
    </div>
  );
}

type analysisPageTab = 'All' | 'Favorite' | 'Archived';
const useAnalysisPage = ({ adminView }: { adminView?: boolean }) => {
  const key = 'analysis-list';
  const { addToast } = useToasts();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [filter, setFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<Set<AnalysisStatus>>(
    new Set(),
  );
  const [currentTab, setCurrentTab] = useState<analysisPageTab>('All');

  const debouncedFilter = useDebounce(filter, 300);

  const params = useMemo<RequestListParams>(
    () => ({
      page,
      pageSize,
      favoriteOnly: currentTab === 'Favorite',
      archivedOnly: currentTab === 'Archived',
      ownedOnly: !adminView,
      filter,
      statusFilterSet: statusFilter,
    }),
    [page, debouncedFilter, statusFilter, currentTab],
  );
  const { data, isLoading, isFetching } = useQuery(
    [key, params],
    () => AnalysisApi.getList(params),
    { keepPreviousData: true, refetchInterval: 5000 },
  );

  useEffect(() => {
    if (page > data?.numberOfPages) {
      setPage(1);
    }
  }, [data]);

  const queryClient = useQueryClient();

  const setFavorite = (analysisId: number, favorite: boolean) => {
    const apiFn = favorite ? AnalysisApi.fave : AnalysisApi.unfave;
    apiFn(analysisId).then(() => queryClient.invalidateQueries(key));
    queryClient.setQueryData([key, params], (old: AnalysisPage) => {
      const newInfos = old.analysisInfos.map((it) => {
        if (it.id === analysisId) {
          return { ...it, favorite };
        }
        return it;
      });
      return { ...old, analysisInfos: newInfos };
    });
  };

  const archive = (analysisId: number) => {
    addToast('Archived analysis', 'success');
    AnalysisApi.archive(analysisId).then(() =>
      queryClient.invalidateQueries(key),
    );
  };

  const restore = (analysisId: number) => {
    addToast('Restored analysis', 'success');
    AnalysisApi.restore(analysisId).then(() =>
      queryClient.invalidateQueries(key),
    );
  };

  const cancel = async (id: number) => {
    await AnalysisApi.cancel(id);
    queryClient.invalidateQueries(key);
  };

  return {
    page,
    data,
    isFetching,
    isLoading,
    setPage,
    pageSize,
    setPageSize,
    setFavorite,
    cancel,
    filter,
    setFilter,
    setStatusFilter,
    statusFilter,
    currentTab,
    setCurrentTab,
    archive,
    restore,
  };
};

export function AnalysisTable({
  adminView,
  title,
}: {
  adminView?: boolean;
  title: string;
}) {
  const analysisPage = useAnalysisPage({ adminView });
  const user = useUser();
  const history = useHistory();

  const tableHeader = (
    <div className="analysis-item analysis-list-header">
      <div className="analysis-item-name-wrapper">Report name</div>
      <div className="analysis-item-status">Status</div>
      <div className="analysis-item-date">Created on</div>
      <div className="analysis-item-duration">Duration</div>
      <div
        className="analysis-more"
        style={{ marginLeft: 'auto', marginRight: '0' }}
      />
    </div>
  );

  if (analysisPage.data === undefined) {
    return (
      <div className="AnalysisTable__content">
        <Loading />
      </div>
    );
  }

  const fromCount = (analysisPage.page - 1) * analysisPage.pageSize + 1;
  const toCount = Math.min(
    analysisPage.page * analysisPage.pageSize,
    analysisPage.data.numberOfAllAnalyses,
  );
  const totalCount = analysisPage.data.numberOfAllAnalyses;
  const list = analysisPage.data;

  const noFilters = analysisPage.filter.length === 0;

  return (
    <div className="AnalysisTable__content">
      <TableAnnouncement />
      <Box>
        <Title>{title}</Title>
        <Heading level={2} fontWeight="bold">
          {title}
        </Heading>
        <ReportsTrialBox />
        <div className="analysis-list-filters">
          <Input
            placeholder="Search reports"
            onChange={(e) => analysisPage.setFilter(e.target.value)}
            value={analysisPage.filter}
            data-test="search-reports"
            $width="300px"
            prepend={<SearchIcon color="#ccc" />}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <StatusFilter
            statusFilterSet={analysisPage.statusFilter}
            setStatusFilterSet={analysisPage.setStatusFilter}
          />
          <SimpleRadioGroup
            $ml="3sp"
            value={analysisPage.currentTab}
            options={['All', 'Favorite', 'Archived'] as analysisPageTab[]}
            onChange={(favoriteFilter) => {
              analysisPage.setPage(1);
              analysisPage.setCurrentTab(favoriteFilter);
            }}
          />

          {analysisPage.isFetching && (
            <Delayed timeoutMs={300}>
              <Spacer />
              <SpinnerIcon spin size="lg" />
            </Delayed>
          )}
        </div>
        <div className="AnalysisTable-wrapper">
          {list.numberOfPages > 0 && (
            <Pagination
              page={analysisPage.page - 1}
              pageCount={list.numberOfPages}
              onChange={(e) => analysisPage.setPage(e.selected + 1)}
              fromCount={fromCount}
              toCount={toCount}
              totalCount={totalCount}
            />
          )}
          <div data-test-id="AnalysisList" className="analysis-list">
            {list.analysisInfos.length > 0 && tableHeader}
            {list.analysisInfos.map((analysis: AnalysisInfo, i: number) => (
              <div
                onClick={(e) => {
                  const url = analysisUrl(analysis.type, analysis.id);
                  if (e.ctrlKey || e.metaKey) {
                    window.open(url, '_blank');
                  } else {
                    history.push(url);
                  }
                }}
                key={i}
              >
                <AnalysisListItem
                  analysis={analysis}
                  canCancel={true}
                  showOwner={adminView}
                  userRole={user?.role}
                  isArchived={analysisPage.currentTab === 'Archived'}
                  postAnalysisCancel={() => analysisPage.cancel(analysis.id)}
                  removeAsFavorite={() =>
                    analysisPage.setFavorite(analysis.id, false)
                  }
                  archive={() => analysisPage.archive(analysis.id)}
                  restore={() => analysisPage.restore(analysis.id)}
                  markAsFavorite={() =>
                    analysisPage.setFavorite(analysis.id, true)
                  }
                />
              </div>
            ))}
            {noFilters &&
              analysisPage.currentTab === 'All' &&
              list.analysisInfos.length === 0 && (
                <div className="analysis-list-empty">
                  <h3 className="empty-title">No reports</h3>
                  <p className="empty-description">
                    You don't have any reports yet
                  </p>
                  <Link to="/new">
                    <Button prepend={<Plus size={15} />} variant="primary">
                      New report
                    </Button>
                  </Link>
                </div>
              )}
            {noFilters &&
              analysisPage.currentTab === 'Favorite' &&
              list.analysisInfos.length === 0 && (
                <div className="analysis-list-empty">
                  <h3 className="empty-title">No favorite reports</h3>
                  <p className="empty-description">
                    Use <Star size={20} /> button to mark analysis as favorite
                  </p>
                </div>
              )}
            {noFilters &&
              analysisPage.currentTab === 'Archived' &&
              list.analysisInfos.length === 0 && (
                <div className="analysis-list-empty">
                  <h3 className="empty-title">No archived reports</h3>
                  <p className="empty-description">
                    Use <DeleteIcon size={1} /> button to archive reports
                  </p>
                </div>
              )}
            {!noFilters && list.analysisInfos.length === 0 && (
              <div className="analysis-list-empty">
                <h3 className="empty-title">Nothing found</h3>
                <p className="empty-description">
                  Try changing search parameters
                </p>
              </div>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
}
