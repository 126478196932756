import { ReactNode } from 'react';
import { Button } from 'tombac';
import styled from 'styled-components';
import { css } from 'styled-components';

interface Props {
  icon: ReactNode;
  onClick: () => void;
  isMonthAvailable: boolean;
  className?: string;
}

const BaseChangeMonthButton = ({
  onClick,
  icon,
  className,
  isMonthAvailable,
}: Props) => {
  return (
    <Button
      onClick={isMonthAvailable ? onClick : undefined}
      prepend={icon}
      variant="flat"
      size="s"
      className={className}
      shape="circle"
    />
  );
};

export const ChangeMonthButton = styled(BaseChangeMonthButton)`
  cursor: default;
  opacity: 0;
  transition: opacity 0.1s ease-out;
  ${({ isMonthAvailable }) =>
    isMonthAvailable
      ? css`
          cursor: pointer;
          opacity: 1;
        `
      : ''}
`;
