import { lineString } from '@turf/turf';
import { AnalysisType } from 'model/AnalysisDto';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useMenu } from 'reducers/menuReducer';
import { SelectedLinkRequest, useLinkRequest } from './linkRequests';

export function SelectedLinkRequestPage() {
  const [, setMenu, { resetMenu }] = useMenu();
  const request = useLinkRequest<SelectedLinkRequest>();

  useEffect(() => {
    request.timeRanges.forEach((tr) => {
      if (tr.startTime === '00:00' && tr.endTime === '24:00') {
        tr.startTime = '00:00';
        tr.endTime = '00:00';
      }
      if (tr.endTime === '24:00') {
        tr.endTime = '00:00';
      }
    });

    request.dateRanges.forEach((dr) => {
      if (!dr.exclusions) {
        dr.exclusions = [];
      }
    });

    resetMenu();
    setMenu({
      name: request.name ?? 'Selected link report',
      type: AnalysisType.SelectedLink,
      mapVersion: request.mapVersion,
      mapType: request.mapType,
      timeRanges: request.timeRanges ?? [],
      dateRanges: request.dateRanges ?? [],
      links: [lineString(request.link)],
    });
  }, []);

  return <Redirect to="/new/dates" />;
}
