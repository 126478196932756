import {
  useMeDetailsQuery,
  UserRole,
  useUserProduct,
  AccessType,
  MeDetailsSafe,
  UserSettings,
} from 'legoland-sdk/dist/experimental';

export function useUser(): MeDetailsSafe | undefined {
  const meDetails = useMeDetailsQuery()?.data?.meDetails;

  return meDetails;
}

export function useIsAdmin(): boolean {
  const meDetails = useMeDetailsQuery()?.data?.meDetails;

  return meDetails?.role === UserRole.Admin;
}

export function useIsTrial(): boolean {
  const product = useUserProduct();

  if (!product) return false;

  return (
    product.access.status === 'GRANTED' &&
    (product.access as any).accessType === AccessType.Evaluation
  );
}

export function useSettings(): UserSettings | undefined {
  return useUser()?.settings;
}
