import Immutable from 'immutable';
import { DateRangeDto, TimeRange } from 'model/TimeDto';

interface ResultItem {
  trips: number;
}
const ResultItem = (trips: number): ResultItem => ({ trips });
ResultItem.empty = { trips: 0 };
ResultItem.merge = (a: ResultItem, b: ResultItem) => ({
  trips: a.trips + b.trips,
});

type Ids = number[] | Immutable.Set<number>;
const idsEmpty = (ids: Ids) =>
  ids === undefined || (ids as any).length === 0 || (ids as any).size === 0;

export interface ReadableResult {
  get(o: number, d: number, v?: number): ResultItem;
  getOf(origins: Ids, destinations: Ids, vias?: Ids): ResultItem;
  meta(): {
    dateRange: DateRangeDto;
    timeRange: TimeRange;
    sumOfTrips: number;
  };
}

export function ListResult(
  cells: any[],
  dateRange: DateRangeDto,
  timeRange: TimeRange,
): ReadableResult {
  const map: Map<number, ResultItem> = new Map();

  // Generates unique number by placing each number in single
  // 15 bits per origin, via and destination number
  const createKey = (o: number, v: number, d: number) => {
    let result = o;
    result = 32768 * result + v;
    result = 32768 * result + d;
    return result;
  };
  let sumOfTrips = 0;
  cells.forEach(([o, d, v, trips]) => {
    map.set(createKey(o, d, v), {
      trips,
    });
    sumOfTrips += trips;
  });
  cells = null;

  const get = (o: number, d: number, v?: number) => {
    if (v === null || v === undefined || v < 0) {
      v = d;
    }
    return map.get(createKey(o, d, v)) || { trips: 0 };
  };

  const getOf = (origins: Ids, destinations: Ids, vias?: Ids): ResultItem => {
    const viasIsEmpty = idsEmpty(vias);
    let result = ResultItem.empty;
    origins.forEach((o: number) => {
      destinations.forEach((d: number) => {
        if (viasIsEmpty) {
          result = ResultItem.merge(result, get(o, d));
        } else {
          vias.forEach(
            (v: number) => (result = ResultItem.merge(result, get(o, d, v))),
          );
        }
      });
    });
    return result;
  };

  const meta = () => ({ dateRange, timeRange, sumOfTrips });

  return {
    get,
    getOf,
    meta,
  };
}
