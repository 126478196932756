import styled from 'styled-components';
import { PropsWithPropStyling, tombac } from 'tombac';

export const Container = styled.div<PropsWithPropStyling>`
  display: inline-block;
  position: relative;
  width: ${tombac.space(20)};

  & > .TombacInput {
    width: 100%;
  }
`;

export const CalendarContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 50px;
  padding: 3px 8px;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 2px 0 #bebebe;
  border-radius: 2px;
  z-index: 1000;
`;

export const YearMonthLabel = styled.span`
  flex: 1;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Gotham';
`;

export const MonthContainer = styled.div`
  display: flex;
  margin-right: -30px;
  margin-left: 10px;
  justify-content: center;
`;

export const StyledYearSelect = styled.select`
  border: none;
  margin-right: -14px;
  padding: 10px 10px 8px 2px;
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
  font-family: 'Gotham';
  font-size: 13px;
`;
