import { LineString } from '@turf/helpers';
import { RegionDto } from './RegionDto';
import { TimeDto } from './TimeDto';

export enum DataSource {
  /** Default last 2 years without 1 week*/
  ALL = 'ALL',
  ALL_PASSENGER = 'ALL_PASSENGER',
  ALL_FLEET = 'ALL_FLEET',
  /** Extended date range, limited data */
  LIMITED = 'LIMITED',
  LIMITED_PASSENGER = 'LIMITED_PASSENGER',
  LIMITED_FLEET = 'LIMITED_FLEET',
}

export enum MapMatchOption {
  Auto = 'AUTO',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export interface SelectedLinkAnalysisRequest {
  name: string;
  time: TimeDto;
  map: {
    type: string;
    version: string;
  };
  link: LineString;
  dataSources: DataSource;
  excludeMobile?: boolean;
}

export interface FlowMatrixAnalysisRequest {
  name: string;
  organizations?: string[];
  time: TimeDto;
  dataSources: DataSource;
  regions: RegionDto[];
  excludeMobile?: boolean;
  tripStats?: boolean;
  passMatrix?: boolean;
  mapMatchOption?: MapMatchOption;
}

export type AnalysisRequest =
  | SelectedLinkAnalysisRequest
  | FlowMatrixAnalysisRequest;

export interface OrganizationsDto {
  type: 'WHITELIST' | 'BLACKLIST';
  organizations: string[];
}

export interface EstimateDurationRequest {
  time: TimeDto;
  regions: RegionDto[];
}
