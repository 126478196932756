import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, LineString } from '@turf/turf';
import { AnalysisDraft } from 'api/DraftApi';
import { ODOrganisations } from 'components/AnalysisNew/configs/organizations';
import { DataType, VehicleTypes } from 'fontModels/DataSource';
import RegionOption from 'logic/region/regionOption';
import { RegionStorage } from 'logic/storage/regionStorage';
import { DayOfWeek } from 'model/DayOfWeek';
import { RegionDto } from 'model/RegionDto';
import { DateRangeDto, TimeRange, TimeRangeCondition } from 'model/TimeDto';
import { equals } from 'ramda';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnalysisType } from '../model/AnalysisDto';
import { MapMatchOption } from '../model/AnalysisParameters';

export type SelectedLinkEdge = Feature<
  LineString,
  {
    // Has id when edited from UI
    id?: string;
    // Has edgeIds when returned from API
    edgeIds?: string[];
  }
>;

export interface MenuState {
  type: AnalysisType;
  dataType: DataType;
  vehicleTypes: VehicleTypes;
  excludeMobile: boolean;
  regions: RegionDto[];
  links: SelectedLinkEdge[];
  passMatrix: boolean;
  name: string;
  organizations?: Set<string>;
  tripStats: boolean;
  zoneId: string;
  dateRanges: DateRangeDto[];
  timeRanges: TimeRange[];
  daysOfWeek: DayOfWeek[];
  timeRangeCondition?: TimeRangeCondition;
  regionDrawingOption?: RegionOption;
  mapMatchOption: MapMatchOption;
  selectedDateRangeId: number;
  selectedTimeRangeId: number;
  isEdited: boolean;
  startedFromDraftId?: number;
  mapVersion?: string;
  mapType: string;
}

const initialMenuState: MenuState = {
  type: AnalysisType.FlowMatrix,
  dataType: DataType.All,
  vehicleTypes: VehicleTypes.All,
  excludeMobile: false,
  passMatrix: true,
  name: '',
  organizations: new Set(ODOrganisations),
  tripStats: true,
  regions: [],
  links: [],
  zoneId: 'Etc/UTC',
  daysOfWeek: Object.values(DayOfWeek),
  dateRanges: [],
  timeRanges: [],
  timeRangeCondition: TimeRangeCondition.WHOLE,
  selectedDateRangeId: 0,
  selectedTimeRangeId: 0,
  isEdited: false,
  startedFromDraftId: undefined,
  mapMatchOption: MapMatchOption.Auto,
  mapVersion: undefined,
  mapType: 'DSEG_NOSPLIT',
};

export const menuSlice = createSlice({
  name: 'Menu',
  initialState: initialMenuState,
  reducers: {
    addTimeRange: (state, action: PayloadAction<TimeRange>) => {
      if (!state.timeRanges.find((tr) => equals(tr, action.payload))) {
        state.timeRanges = [...state.timeRanges, action.payload];
      }
    },

    addDateRange: (state, action: PayloadAction<DateRangeDto>) => {
      if (!state.dateRanges.find((dr) => equals(dr, action.payload))) {
        state.dateRanges = [...state.dateRanges, action.payload];
      }
    },

    set: (state, action: PayloadAction<Partial<MenuState>>) => {
      return { ...state, isEdited: true, ...action.payload };
    },
    resetRegions: (state) => {
      state.regions = [];
    },

    addRegions: (state, action: PayloadAction<{ regions: RegionDto[] }>) => {
      const { regions } = action.payload;
      state.regions = regions;
      state.isEdited = true;
      RegionStorage.save(regions);
    },
    reset: () => initialMenuState,
    continueFromDraft: (state, action: PayloadAction<AnalysisDraft>) => {
      const draft = action.payload as AnalysisDraft;
      state.name = draft.name;
      state.organizations = new Set(draft.organizations);
      state.passMatrix = draft.passMatrix;
      state.type = draft.type;
      state.tripStats = draft.tripStats;
      state.startedFromDraftId = draft.id;
      state.isEdited = false;
      state.zoneId = draft.timeDefinition.zoneId;
      if (draft.timeDefinition.timeRanges) {
        state.timeRanges = draft.timeDefinition.timeRanges;
      }
      if (draft.timeDefinition.daysOfWeek) {
        state.daysOfWeek = draft.timeDefinition.daysOfWeek;
      }
      if (draft.timeDefinition.dateRanges) {
        state.dateRanges = draft.timeDefinition.dateRanges;
      }
      if (draft.timeDefinition.timeRangeCondition) {
        state.timeRangeCondition = draft.timeDefinition.timeRangeCondition;
      }
      if (draft.regions) {
        state.regions = draft.regions;
      }
      if (draft.map && draft.link && draft.edgeIds) {
        state.mapType = draft.map.type;
        state.mapVersion = draft.map.version;
        state.links = [
          {
            type: 'Feature',
            geometry: draft.link,
            properties: {
              edgeIds: draft.edgeIds,
            },
          },
        ];
      }
    },
  },
});

interface Actions {
  addRegions: (r: RegionDto[]) => any;
  resetRegions: () => void;
  resetMenu: () => void;
  continueFromDraft: (draft: AnalysisDraft) => any;
  addDateRange: (dateRange: DateRangeDto) => void;
  addTimeRange: (timeRange: TimeRange) => void;
}

export const useMenu = (): [
  MenuState,
  (p: Partial<MenuState>) => any,
  Actions,
] => {
  const dispatch = useDispatch();
  const setMenu = useCallback(
    (partial: Partial<MenuState>) => {
      dispatch(menuSlice.actions.set(partial));
    },
    [dispatch],
  );
  const addRegions = useCallback(
    (regions: RegionDto[]) =>
      dispatch(menuSlice.actions.addRegions({ regions })),
    [dispatch],
  );
  const resetRegions = useCallback(
    () => dispatch(menuSlice.actions.resetRegions()),
    [dispatch],
  );
  const resetMenu = useCallback(() => dispatch(menuSlice.actions.reset()), [
    dispatch,
  ]);
  const continueFromDraft = useCallback(
    (draft) => dispatch(menuSlice.actions.continueFromDraft(draft)),
    [dispatch],
  );

  const addDateRange = useCallback(
    (date: DateRangeDto) => dispatch(menuSlice.actions.addDateRange(date)),
    [dispatch],
  );

  const addTimeRange = useCallback(
    (time: TimeRange) => dispatch(menuSlice.actions.addTimeRange(time)),
    [dispatch],
  );

  const menu = useSelector((state: any) => state.menu as MenuState);

  return [
    menu,
    setMenu,
    {
      addRegions,
      resetRegions,
      resetMenu,
      continueFromDraft,
      addDateRange,
      addTimeRange,
    },
  ];
};
