import AnalysisApi from 'api/AnalysisApi';
import { apiKey } from 'api/api';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { createSelectedLinkUrl } from 'components/RequestPages/linkRequests';
import {
  createRequest,
  dataSourceToDataType,
  dataSourceToVehicleTypes,
} from 'logic/analysis/analysisUtils';
import { appConfig } from 'logic/appConfig';
import { Analysis, AnalysisType, getAnalysisTypeId } from 'model/AnalysisDto';
import {
  AnalysisRequest,
  DataSource,
  SelectedLinkAnalysisRequest,
} from 'model/AnalysisParameters';
import { DayOfWeek } from 'model/DayOfWeek';
import { TimeRangeCondition } from 'model/TimeDto';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MenuState, useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import { Button, FormGroup, InlineText, Input, Tooltip } from 'tombac';
import {
  ApiLinkIcon,
  CopyIcon,
  ExternalLinkIcon,
  SpinnerIcon,
  TickIcon,
} from 'tombac-icons';
import { ODOrganisations } from '../configs/organizations';

function CopyField({ value }: { value: string }) {
  const [state, setState] = useState<'idle' | 'copied'>('idle');
  const ref = useRef<HTMLInputElement>();
  const timerRef = useRef<any>();

  // Copy value from input to clipboard
  const onClick = () => {
    if (!ref.current) return;

    ref.current.select();
    document.execCommand('copy');
    setState('copied');

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setState('idle');
    }, 3000);
  };

  return (
    <Input
      ref={ref}
      $width="300px"
      append={
        <Button
          $width="90px"
          prepend={state === 'idle' ? <CopyIcon /> : <TickIcon />}
          size="s"
          $mr="-13px"
          onClick={onClick}
        >
          {state === 'idle' ? 'Copy' : 'Copied'}
        </Button>
      }
      value={value}
      readOnly
    />
  );
}

const TooltipContent = styled.div`
  margin: -16px;
  display: flex;
  flex-direction: column;
  width: 400px;
`;

function analysisToRequest(analysis: Analysis) {
  const menu: Partial<MenuState> = {
    type: analysis.info.type ?? AnalysisType.FlowMatrix,
    passMatrix: analysis.info?.passMatrix ?? false,
    name: analysis.info.name ?? '',
    organizations:
      new Set(analysis.info?.organizations) ?? new Set(ODOrganisations),
    tripStats: analysis.info.tripStats ?? true,
    zoneId: analysis.info?.timeDefinition?.zoneId ?? 'Etc/UTC',
    dateRanges: analysis.info?.timeDefinition?.dateRanges ?? [],
    timeRanges: analysis.info?.timeDefinition?.timeRanges ?? [],
    daysOfWeek:
      analysis.info?.timeDefinition?.daysOfWeek ?? Object.values(DayOfWeek),
    timeRangeCondition:
      analysis.info?.timeDefinition?.timeRangeCondition ??
      TimeRangeCondition.WHOLE,
    startedFromDraftId: undefined,
    isEdited: false,
    dataType: dataSourceToDataType(
      analysis.info?.dataSources ?? DataSource.ALL,
    ),
    vehicleTypes: dataSourceToVehicleTypes(
      analysis.info?.dataSources ?? DataSource.ALL,
    ),
    mapVersion: analysis.info?.map?.version,
    mapType: analysis.info?.map?.type,
  };
  if (analysis.info.type === AnalysisType.FlowMatrix) {
    menu.regions = analysis.regions;
  } else {
    menu.links = analysis.regions as any;
  }

  return createRequest(menu as MenuState);
}

export function ApiLinkButton({
  analysisId,
  type,
}: {
  analysisId?: number;
  type?: AnalysisType;
}) {
  const isExistingReport = !!analysisId;
  const [isOpen, setIsOpen] = useState(false);
  const [menu] = useMenu();

  const [request, setRequest] = useState<AnalysisRequest | undefined>(() =>
    analysisId !== undefined && type !== undefined
      ? undefined
      : createRequest(menu),
  );

  useEffect(() => {
    if (!isOpen || !isExistingReport) return;
    AnalysisApi.get(analysisId, type).then((analysis) => {
      setRequest(analysisToRequest(analysis));
    });
  }, [analysisId, type, isOpen]);

  const selectedLinkUrl = useMemo(() => {
    if (
      request === undefined ||
      (request as SelectedLinkAnalysisRequest).link === undefined
    ) {
      return;
    }

    const r = request as SelectedLinkAnalysisRequest;
    const url = createSelectedLinkUrl(
      {
        name: r.name,
        dateRanges: r.time.dateRanges as any,
        timeRanges: r.time.timeRanges,
        daysOfWeek: r.time.daysOfWeek,
        link: r.link.coordinates,
        dataSource: r.dataSources as any,
        mapType: r.map.type,
        mapVersion: r.map.version,
        zoneId: r.time.zoneId,
        timeRangeCondition: r.time.timeRangeCondition as any,
      },
      location.origin,
    );
    return url;
  }, [menu]);

  const createDocsLink =
    menu.type === AnalysisType.SelectedLink
      ? 'https://developer.tomtom.com/od-analysis/documentation/analysis/selected-link-analysis/analysis-creation'
      : 'https://developer.tomtom.com/od-analysis/documentation/analysis/flow-matrix-analysis/analysis-creation';

  const previewDocsLink =
    menu.type === AnalysisType.SelectedLink
      ? 'https://developer.tomtom.com/od-analysis/documentation/analysis/selected-link-analysis/analysis-preview'
      : 'https://developer.tomtom.com/od-analysis/documentation/analysis/flow-matrix-analysis/analysis-preview';

  const previewLink = `${appConfig.apiUrl}/1/analysis/${getAnalysisTypeId(
    type,
  )}/${analysisId}?key=${apiKey()}
    `;

  return (
    <Tooltip
      usePortal
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      content={
        <TooltipContent>
          {isExistingReport && (
            <FormGroup
              label="Report Preview API"
              hint="Download report preview as a JSON"
              $borderBottom="1px solid #eee"
              $p="2sp"
              toolbar={
                <InlineText
                  as="a"
                  target="_blank"
                  href={previewDocsLink}
                  variant="interactive"
                >
                  API Documentation
                  <ExternalLinkIcon />
                </InlineText>
              }
            >
              <CopyField value={previewLink} />
            </FormGroup>
          )}
          <FormGroup
            label="Report as Request"
            hint="Request body for creating report using API"
            $borderBottom="1px solid #eee"
            $p="2sp"
            toolbar={
              <InlineText
                as="a"
                target="_blank"
                href={createDocsLink}
                variant="interactive"
              >
                API Documentation
                <ExternalLinkIcon />
              </InlineText>
            }
          >
            {request === undefined ? (
              <SpinnerIcon spin />
            ) : (
              <CopyField value={JSON.stringify(request)} />
            )}
          </FormGroup>

          {selectedLinkUrl !== undefined && (
            <FormGroup
              $p="2sp"
              label="Report Request Link"
              $mb="1sp"
              hint="Share this report request as a link"
            >
              <CopyField value={selectedLinkUrl} />
            </FormGroup>
          )}
        </TooltipContent>
      }
    >
      {({ ref, toggle }) => (
        <SimpleTooltip content="API Info" placement="top" usePortal>
          <Button
            prepend={<ApiLinkIcon />}
            shape="circle"
            $background="transparent"
            onClick={toggle}
            ref={ref}
          />
        </SimpleTooltip>
      )}
    </Tooltip>
  );
}
