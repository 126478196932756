import { SelectedLinkResultFormat } from 'api/AnalysisApi';
import { DataSource, MapMatchOption } from './AnalysisParameters';
import { RegionDto } from './RegionDto';
import { DateRangeDto, TimeDto, TimeRange } from './TimeDto';

export enum AnalysisStatus {
  WAITING = 'WAITING_ARGO',
  RUNNING = 'RUNNING_ARGO',
  ENDED = 'ENDED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum AnalysisType {
  FlowMatrix = 'FLOW_MATRIX',
  SelectedLink = 'SELECTED_LINK',
}

export const getAnalysisTypeId = (type: AnalysisType) =>
  ({
    [AnalysisType.FlowMatrix]: 'flowmatrix',
    [AnalysisType.SelectedLink]: 'selected-link',
  }[type]);

export interface AnalysisResultDimension {
  name: string;
  values: string[];
}

export interface AnalysisResult {
  dimensions: AnalysisResultDimension[];
  flows: any[];
  [s: string]: any;
}

export interface Analysis {
  info: AnalysisInfo;
  results: ResultLink[];
  regions: RegionDto[];
}

export interface ResultLink {
  dateRange: DateRangeDto;
  timeRange: TimeRange;
  tripsWholeResultLink: string;
  /** Result size in MB */
  tripsResultFileSize: number;
  tripsPartialResultLink: string;
  hoursOfDayHistogramPartialResultLink?: string;
  lengthsHistogramPartialResultLink?: string;
  durationsHistogramPartialResultLink?: string;
  // Selected link
  incomingResultUrl: Map<SelectedLinkResultFormat, string>;
  outgoingResultUrl: Map<SelectedLinkResultFormat, string>;
}

export interface AnalysisDto {
  analysisInfo: AnalysisInfo;
  results: ResultLink[];
  regionsLink: string;
}

export interface AnalysisInfo {
  id: number;
  name: string;
  type: AnalysisType;
  owner: string;
  apiKey: string;
  status: AnalysisStatus;
  currentProgress: number;
  estimatedDuration?: number;
  dataSources: DataSource;
  creationTime: string;
  startAnalysisTime: string;
  endAnalysisTime: string;
  tripsCounted: number;
  timeDefinition: TimeDto;
  archived?: boolean;
  favorite?: boolean;
  mapMatchOption?: MapMatchOption;
  isMapMatched?: boolean;
  passMatrix?: boolean;
  tripStats?: boolean;
  organizations?: string[];
  edgeIds?: string[];
  map?: {
    type: string;
    version: string;
  };
}

export interface AnalysisPage {
  analysisInfos: AnalysisInfo[];
  numberOfAllAnalyses: number;
  numberOfPages: number;
}

export interface AnalysisDurationEstimation {
  durationInSeconds: number;
}

export interface AnalysisSummaryDto {
  dateRange: DateRangeDto;
  timeRange: TimeRange;
  tripsCounted: number;
}

export interface AnalysisSummariesDto {
  summaries: AnalysisSummaryDto[];
}
