import { convertFromDto } from '../logic/region/requestConversion';
import { convertDtoToGeoJson } from '../logic/region/dtoConverting';
import { RegionDto } from '../model/RegionDto';

enum RegionVersion {
  V0,
  V1,
}

const getRegionsVersion = (regions: any[]): RegionVersion => {
  // Assume that every region is the same format
  const region = regions[0];

  if (region?.type === 'Feature') {
    return RegionVersion.V1;
  }
  if (region.shape && region.name) {
    return RegionVersion.V0;
  }
};

const handleRegionVersions = (regions: any[]): RegionDto[] => {
  const version = getRegionsVersion(regions);
  if (version === RegionVersion.V1) {
    return regions;
  }
  if (version === RegionVersion.V0) {
    return regions
      .map(convertFromDto)
      .map(convertDtoToGeoJson as any) as RegionDto[];
  }
};

export { handleRegionVersions };
