import { Box, Button, Heading } from 'tombac';
import styled from 'styled-components';

interface Properties {
  analysisName: string;
  onClose: () => void;
  onCancel: () => void;
}

const Container = styled.div`
  width: 500px;
  background-color: white;
  padding: 16px 24px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.2);
  z-index: 5;
`;

function AnalysisCancelationModal(props: Properties) {
  return (
    <Container>
      <Heading level={2}>Cancel analysis</Heading>
      <p>
        Are you sure you want to cancel analysis <b>{props.analysisName}</b>?
        <br />
        Any progress will be lost.
      </p>

      <Box $width="100%" $display="flex">
        <Button onClick={props.onCancel} variant="primary" $ml="auto">
          Yes
        </Button>
        <Button onClick={props.onClose} $ml="1sp">
          No
        </Button>
      </Box>
    </Container>
  );
}

export default AnalysisCancelationModal;
