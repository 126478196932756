import { DataType, VehicleTypes } from 'fontModels/DataSource';
import { AnalysisType } from '../../model/AnalysisDto';
import {
  AnalysisRequest,
  DataSource,
  FlowMatrixAnalysisRequest,
  MapMatchOption,
  SelectedLinkAnalysisRequest,
} from '../../model/AnalysisParameters';
import { RegionDto, RegionDtoProps } from '../../model/RegionDto';
import { TimeDto } from '../../model/TimeDto';
import { MenuState } from '../../reducers/menuReducer';

/**
 * Removes optional fields
 */
const prepareRegions = (regions: RegionDto[]) => {
  return regions.map((r) => {
    const props: RegionDtoProps = {
      name: r.properties.name,
    };

    const preparedRegion = { ...r, properties: props };
    return preparedRegion;
  });
};

const prepareOrgs = (orgs: Set<string>): string[] =>
  orgs.size === 0 ? undefined : Array.from(orgs);

export const prepareDataSources = (
  dataType: DataType,
  vehicleTypes: VehicleTypes,
): DataSource => {
  if (vehicleTypes === VehicleTypes.All || !vehicleTypes) {
    return dataType === DataType.All ? DataSource.ALL : DataSource.LIMITED;
  } else {
    return DataSource[`${dataType}_${vehicleTypes}`] as DataSource;
  }
};

export const dataSourceToDataType = (dataSource: DataSource): DataType => {
  if (
    dataSource === DataSource.ALL ||
    dataSource === DataSource.ALL_FLEET ||
    dataSource === DataSource.ALL_PASSENGER
  ) {
    return DataType.All;
  } else {
    return DataType.Limited;
  }
};

export const dataSourceToVehicleTypes = (
  dataSource: DataSource,
): VehicleTypes => {
  switch (dataSource) {
    case DataSource.ALL_FLEET || DataSource.LIMITED_FLEET:
      return VehicleTypes.Fleet;
    case DataSource.ALL_PASSENGER || DataSource.LIMITED_PASSENGER:
      return VehicleTypes.Passenger;
    case DataSource.ALL || DataSource.LIMITED:
      return VehicleTypes.All;
  }
};

export const createRequest = (menu: MenuState): AnalysisRequest => {
  const { name, organizations, type } = menu;

  const timeDto: TimeDto = {
    dateRanges: menu.dateRanges,
    daysOfWeek: menu.daysOfWeek,
    timeRanges: menu.timeRanges,
    zoneId: menu.zoneId,
    timeRangeCondition: menu.timeRangeCondition,
  };

  let requestBase = {
    name,
    time: timeDto,
    dataSources: prepareDataSources(menu.dataType, menu.vehicleTypes),
    organizations: prepareOrgs(organizations),
    excludeMobile: menu.excludeMobile,
  };

  if (!requestBase.excludeMobile) {
    delete requestBase.excludeMobile;
  }

  if (type === AnalysisType.FlowMatrix) {
    let request: FlowMatrixAnalysisRequest = {
      ...requestBase,
      passMatrix: menu.passMatrix,
      regions: prepareRegions(menu.regions),
    };

    if (menu.mapMatchOption !== MapMatchOption.Auto) {
      request.mapMatchOption = menu.mapMatchOption;
    }

    if (menu.tripStats) {
      request.tripStats = menu.tripStats;
    }
    return request;
  }

  if (type === AnalysisType.SelectedLink) {
    return {
      ...requestBase,
      name,
      link: {
        type: 'LineString',
        coordinates: menu.links.flatMap((it) => it.geometry.coordinates),
      },
      map: {
        version: menu.mapVersion,
        type: menu.mapType,
      },
    } as SelectedLinkAnalysisRequest;
  }

  throw new Error(`Unknown analysis type ${type}`);
};

export const analysisUrl = (
  type: AnalysisType,
  id: number,
  action: 'view' | 'share' = 'view',
) => {
  return `/${action}/${
    type === AnalysisType.SelectedLink ? 'selected-link/' : ''
  }${id}`;
};
