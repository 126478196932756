import { AnalysisType } from 'model/AnalysisDto';
import { useState } from 'react';
import { Button, Modal } from 'tombac';
import { ShareLinkIcon } from 'tombac-icons';
import AnalysisShare from './AnalysisShare';
import { SimpleTooltip } from './SimpleTooltip';

interface Props {
  size: 'small' | 'big';
  analysisId: number;
  type: AnalysisType;
}

export function AnalsysisShareButton({ size, analysisId, type }: Props) {
  const [modal, setModal] = useState(false);

  const openModal = () => setModal(true);

  const button =
    size === 'small' ? (
      <SimpleTooltip content="Share" usePortal placement="top">
        <Button
          prepend={<ShareLinkIcon />}
          variant="flat"
          size="xs"
          shape="circle"
          onClick={openModal}
          data-test="analysis-item-share"
        />
      </SimpleTooltip>
    ) : (
      <SimpleTooltip content="Share" placement="top" usePortal>
        <Button
          prepend={<ShareLinkIcon />}
          onClick={openModal}
          data-test="analysis-item-share"
          shape="circle"
          $background="transparent"
        />
      </SimpleTooltip>
    );

  return (
    <>
      {modal && (
        <Modal isOpen onRequestClose={() => setModal(false)}>
          <div
            className="AnalysisListItem__cancel-modal"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <h2 style={{ marginTop: 0 }}>Share with others</h2>
            <AnalysisShare analysisId={analysisId} type={type} />
            <Button variant="primary" onClick={() => setModal(false)}>
              Done
            </Button>
          </div>
        </Modal>
      )}
      {button}
    </>
  );
}
