import { AnalysisTable } from '../AnalysisTable/AnalysisTable';
import Title from '../Layout/Title';

const SupportPage = () => {
  return (
    <div className="SupportPage">
      <Title>Support</Title>
      <AnalysisTable adminView title="Users' reports" />
    </div>
  );
};

export default SupportPage;
