import * as React from 'react';
import TooltipManager from '../Tooltip/TooltipManager';
import './Menu.css';
import MenuItem from './MenuItem';
import MenuSeparator from './MenuSeparator';

export interface MenuProps {
  trigger?: (params: { toggle: () => void }) => void;
  placement?: string;
}

class Menu extends React.Component<any, any> {
  static Item = MenuItem;
  static Separator = MenuSeparator;

  tooltipManagerRef = React.createRef<TooltipManager>();

  render() {
    const { children, trigger, placement = 'bottom', ...rest } = this.props;
    return (
      <TooltipManager
        content={() => children}
        modifiers={{ offset: { offset: '0, 12' } }}
        placement={placement}
        contentClass="Menu"
        hideArrow
        {...rest}
        ref={this.tooltipManagerRef}
      >
        {trigger}
      </TooltipManager>
    );
  }
}

export { Menu };
export default Menu;
