import { FormGroup, Box, useTombac, Text } from 'tombac';
import { useMenu } from 'reducers/menuReducer';
import { Flex } from '../UI/FormUI';
import { RadioInput } from './RadioInput';
import { WarningIcon } from 'tombac-icons';
import styled from 'styled-components';
import LimitsApi from 'api/LimitsApi';
import { DataType } from '../../fontModels/DataSource';
import { DataTooltip } from './DataTooltip';

export const Bold = styled.b`
  color: #000;
  display: inline-block;
  font-weight: 500;
`;

const getWaringText = (
  selectedDataSource: DataType,
  limitedSourcesForRecentDataEnabled: boolean,
) => {
  if (selectedDataSource === DataType.Limited) {
    if (limitedSourcesForRecentDataEnabled) {
      return (
        <>
          This option allows you to analyse{' '}
          <Bold> dates older than 2 years</Bold> <br />
          and data from the <Bold>last 7 days</Bold>. Please note: Report will
          be <br />
          based on a lower volume of data.
        </>
      );
    }
    return (
      <>
        This option allows you to create a report with a date older than 2
        years. <br />
        Please note: Report will be based on a lower volume of data.
      </>
    );
  }
};

export function DataSourceSelect() {
  const [menu, setMenu] = useMenu();
  const limits = LimitsApi.use();
  const tombac = useTombac();
  const options = [
    {
      label: 'Full data support, within last 2 years',
      value: DataType.All,
    },
    {
      label: limits?.limits.limitedSourcesForRecentDataEnabled ? (
        <div style={{ lineHeight: 1.5 }}>
          <Bold>Limited data support</Bold>, more than 2 years, possibility{' '}
          <br />
          to analyse data from the last 7 days
        </div>
      ) : (
        'Limited data support, more than 2 years'
      ),
      value: DataType.Limited,
    },
  ];
  if (!limits?.limits?.limitedDataSourcesEnabled) return null;

  const warningText = getWaringText(
    menu?.dataType,
    limits?.limits.limitedSourcesForRecentDataEnabled,
  );

  return (
    <>
      <FormGroup
        label={
          <Text fontFamily="Gotham" fontSize={12} fontWeight="bold">
            Historical data <DataTooltip limits={limits} />
          </Text>
        }
        $mb="2sp"
      >
        <RadioInput
          options={options}
          value={menu.dataType}
          onChange={(dataType) => setMenu({ dataType })}
          $mt="1sp"
        />
      </FormGroup>
      {warningText && (
        <Box
          style={{
            background: tombac.color('alert', 200),
            border: `1px solid ${tombac.color('alert', 400)}`,
            fontFamily: 'Noway',
            fontSize: '12px',
            lineHeight: 1.4,
            color: '#000',
          }}
          $padding="1.5sp"
          $mb="2sp"
        >
          <Flex direction="row">
            <WarningIcon
              color="#f8b022"
              width="20px"
              size="lg"
              style={{ flexShrink: 0, marginRight: '12px' }}
            />
            <div style={{ lineHeight: 1.5 }}>{warningText}</div>
          </Flex>
        </Box>
      )}
    </>
  );
}
