export const AllOrganizations = [
  'ait',
  'amigo',
  'amigo_ids',
  'arity',
  'arobs',
  'audi',
  'audi_hcp3',
  'audi_tec_tfp',
  'autonavi',
  'basarsoft',
  'bestway',
  'bmw',
  'bmw-mobileye',
  'bridge',
  'cartrack',
  'cdcom',
  'copiloto',
  'ctrack',
  'daimler',
  'daimler-hazard',
  'daimler_gen20x',
  'daimler_ntg6',
  'dcas-test',
  'debug',
  'dtc',
  'eagle-rsd',
  'eagle-rsd-test',
  'easytaxi',
  'ecofleet',
  'eroad',
  'eurogps',
  'evaluation-1',
  'evaluation-10',
  'evaluation-2',
  'evaluation-3',
  'evaluation-4',
  'evaluation-5',
  'evaluation-6',
  'evaluation-7',
  'evaluation-8',
  'evaluation-9',
  'fleet-tomtom',
  'ford-garmin',
  'ford-telenav',
  'formax',
  'forth',
  'frotcom',
  'gdi',
  'gila',
  'gps_bulgaria',
  'gurtam',
  'gurtam_me',
  'gurtam_ru',
  'hefei-taxi',
  'here_eu_dtf',
  'hitachi',
  'hk',
  'hk-gen6-hmns',
  'home',
  'huawei',
  'idata',
  'inavi',
  'itic',
  'junit',
  'lyft',
  'mapillary',
  'microsoft-iot',
  'mireo',
  'mobilisis',
  'moj-taxi',
  'muskoka',
  'muskoka-batch',
  'navman',
  'ndw-nl',
  'nissan',
  'nng',
  'none',
  'onixsat',
  'osm',
  'otonomo',
  'perseus',
  'perseus_alliance_st21',
  'perseus_drop',
  'perseus_fca_r1',
  'perseus_stl_caruso',
  'perseus_volvo_trucks_sem2',
  'philgps',
  'ps_bmw_bikes',
  'ps_cna',
  'ps_daimler_ntg7',
  'ps_daimler_prem',
  'ps_fiat',
  'ps_fiat_tpeg',
  'ps_go_pnd',
  'ps_hk_gen6',
  'ps_hyundai',
  'ps_melco',
  'ps_psa',
  'ps_renault',
  'ps_renault_test',
  'ps_tomtom_bridge',
  'ps_toyota',
  'ps_toyota_prem',
  'ps_volvo_trucks',
  'roadagram-test',
  'safefleet',
  'saic-mg',
  'sensoris-test',
  'sgpss',
  'sincity',
  'sincity_ids',
  'sports',
  'sports-backfill',
  'stickearn',
  'superspring',
  'sygic',
  'taiwan_taxi',
  'taxi31300',
  'telenav',
  'toyota-hazard',
  'toyota-sdo',
  'toyota_aisin',
  'toyota_tmna',
  'tracker',
  'troubleshooting',
  'tt-aci',
  'tt-map',
  'tt-moma',
  'tt_nav',
  'uber',
  'uffizio',
  'unknown',
  'unused_1',
  'verizon',
  'vietmap',
  'vw-entry',
  'waypoint',
  'webtech',
  'wejo',
  'woven',
  'zenuity',
];

export const PassengerOrganizations = [
  'audi_tec_tfp',
  'ctrack',
  'daimler',
  'gila',
  'hk',
  'hk-gen6-hmns',
  'home',
  'ps_hyundai',
  'muskoka-batch',
  'perseus_alliance_st21',
  'ps_daimler_prem',
  'ps_fiat',
  'ps_psa',
  'ps_renault',
  'sgpss',
  'sincity',
  'sygic',
  'uber',
  'vw-entry',
  'waypoint',
];

export const FleetOrganizations = [
  'arobs',
  'basarsoft',
  'cartrack',
  'copiloto',
  'ecofleet',
  'eroad',
  'eurogps',
  'fleet-tomtom',
  'gps_bulgaria',
  'gurtam',
  'gurtam_me',
  'gurtam_ru',
  'idata',
  'mireo',
  'mobilisis',
  'perseus_volvo_trucks_sem2',
  'ps_volvo_trucks',
  'safefleet',
  'tracker',
  'webtech',
];

export const ODOrganisations = [
  ...PassengerOrganizations,
  ...FleetOrganizations,
];
