import { LimitsWithUsage } from 'api/LimitsApi';
import { Box, TooltipOnHover, Text } from 'tombac';
import { HelpIcon } from 'tombac-icons';
import { Bold } from './DataSourceSelect';

export function DataTooltip({ limits }: { limits: LimitsWithUsage }) {
  return (
    <TooltipOnHover
      content={
        <Box $width="350u">
          <Text
            fontFamily="Gotham"
            fontWeight="bold"
            fontSize={12}
            style={{ textTransform: 'uppercase' }}
          >
            Historical data
          </Text>
          {limits?.limits.limitedSourcesForRecentDataEnabled ? (
            <>
              <Text fontSize={12} $color="#7a7e80" $mt="1sp">
                <Bold>Full data support within last 2 years</Bold> - allows you
                to create a report based on full data from the last 2 years
                (without the last 7 days)
              </Text>
              <Text fontSize={12} $color="#7a7e80" $mt="1sp">
                <Bold>Limited data support</Bold>, more than 2 years - allows
                you to create a report with dates older than two years and
                analyse data from the last 7 days. Please note: Report will be
                based on a lower volume of data.
              </Text>
            </>
          ) : (
            <>
              <Text fontSize={12} $color="#7a7e80" $mt="1sp">
                <Bold>Full data support within last 2 years</Bold> allows you to
                create the report based on full data from the last 2 years
              </Text>
              <Text fontSize={12} $color="#7a7e80" $mt="1sp">
                <Bold>Limited data support, more than 2 years</Bold> allows you
                to create a report with a date range older than 2 years.
                <br /> Please note: Report will be based on a lower volume of
                data.
              </Text>
            </>
          )}
        </Box>
      }
      placement="top"
    >
      <HelpIcon color="rgb(123,126,128)" />
    </TooltipOnHover>
  );
}
