import { ApiLinkButton } from 'components/AnalysisNew/Sections/ApiLinkButton';
import { formatDistance, formatDistanceStrict } from 'date-fns';
import format from 'date-fns/format';
import { UserRole } from 'legoland-sdk/dist/experimental';
import { trackEvent } from 'logic/tracking';
import * as React from 'react';
import Check from 'react-feather/dist/icons/check';
import Loader from 'react-feather/dist/icons/loader';
import Minus from 'react-feather/dist/icons/minus';
import MoreHorizontal from 'react-feather/dist/icons/more-horizontal';
import User from 'react-feather/dist/icons/user';
import X from 'react-feather/dist/icons/x';
import XSquare from 'react-feather/dist/icons/x-square';
import { Button } from 'tombac';
import { DeleteIcon, RedoIcon, StarIcon, StarOutlineIcon } from 'tombac-icons';
import analysisTypeName from '../../logic/analysis/analysisTypeName';
import { AnalysisInfo, AnalysisStatus } from '../../model/AnalysisDto';
import FormattedDate from '../UI/FormattedDate/FormattedDate';
import AnalysisCancelationModal from './AnalysisCancelationModal';
import AnalysisCloneButton from './AnalysisCloneButton';
import './AnalysisListItem.css';
import { AnalsysisShareButton } from './AnalysisShareButton';
import { AnalysisStatusIndicator } from './AnalysisStatusIndicator';
import { estimatedDurationLabel } from './estimatedDuration';
import { SimpleTooltip } from './SimpleTooltip';

interface Props {
  analysis: AnalysisInfo;
  showOwner?: boolean;
  canCancel?: boolean;
  showEstimate?: boolean;
  userRole: UserRole;
  isArchived: boolean;

  postAnalysisCancel: () => void;
  markAsFavorite: () => void;
  removeAsFavorite: () => void;
  archive: () => void;
  restore: () => void;
}

const distanceInMinutes = (date1: any, date2: any) =>
  formatDistanceStrict(new Date(date1), new Date(date2), { unit: 'minute' });

const getAnalysisPercent = (analysis: any) =>
  Math.max((analysis.currentProgress / (analysis.maxProgress || 100)) * 100, 0);

const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

const formatTime = (
  { startAnalysisTime, endAnalysisTime }: any,
  formatter: any,
) => {
  if (startAnalysisTime == null) {
    return 'Unknown';
  } else if (endAnalysisTime == null) {
    return capitalizeFirstLetter(
      formatter(new Date(startAnalysisTime), new Date()),
    );
  } else {
    return capitalizeFirstLetter(
      formatter(new Date(startAnalysisTime), new Date(endAnalysisTime)),
    );
  }
};

class AnalysisListItem extends React.Component<Props> {
  state: any = {
    cancelModal: false,
  };

  toggleMenu: any;
  render() {
    const { cancelModal } = this.state;
    const {
      analysis,
      postAnalysisCancel,
      showOwner,
      canCancel,
      markAsFavorite,
      removeAsFavorite,
      archive,
      restore,
      userRole,
      isArchived,
    } = this.props;

    const cancelled = analysis.status === AnalysisStatus.CANCELLED;
    const failed = analysis.status === AnalysisStatus.FAILED;
    const ended = analysis.status === AnalysisStatus.ENDED;
    const running = analysis.status === AnalysisStatus.RUNNING;
    const waiting = analysis.status === AnalysisStatus.WAITING;
    const accepted = analysis.status === AnalysisStatus.ACCEPTED;
    const rejected = analysis.status === AnalysisStatus.REJECTED;
    const isFavorite = analysis.favorite;
    const canArchive = !isArchived && !running && !waiting && !ended;

    const displayCancel = canCancel && (running || waiting);

    const fave = markAsFavorite;
    const unfave = removeAsFavorite;
    const cancel = () => {
      postAnalysisCancel();
      this.setState({ cancelModal: false });
    };
    const openCancelModal = () => {
      this.setState({ cancelModal: true });
    };
    const closeModal = () => {
      this.setState({ cancelModal: false });
    };

    let rowClass = 'analysis-item';
    if (cancelled || failed) {
      rowClass += ' analysis-item-disabled';
    }

    const displayEstimateValue = running && analysis.estimatedDuration;

    return (
      <>
        <div
          className={rowClass}
          onClick={() => {
            trackEvent({ action: 'report results', label: analysis.id });
          }}
          data-test-id="analysis-list-item"
        >
          <div
            className="analysis-item-name-wrapper"
            data-test-id="analysis-list-item-name"
          >
            <div className="analysis-item-type">
              {analysisTypeName(analysis.type)}
            </div>

            {analysis.name}
          </div>
          {showOwner && (
            <div className="analysis-item-owner" title={analysis.owner}>
              <User size={15} />
              <div className="analysis-item-owner-email">{analysis.owner}</div>
            </div>
          )}

          <div className="analysis-item-status">
            {accepted && (
              <AnalysisStatusIndicator
                variant={AnalysisStatus.ACCEPTED}
                icon={<Check size={14} />}
              >
                Ready
              </AnalysisStatusIndicator>
            )}
            {rejected && (
              <AnalysisStatusIndicator
                variant={AnalysisStatus.REJECTED}
                icon={<X size={14} />}
              >
                Rejected
              </AnalysisStatusIndicator>
            )}
            {running && (
              <AnalysisStatusIndicator
                variant={AnalysisStatus.RUNNING}
                icon={<Loader size={14} />}
                rotateIcon={true}
              >
                In progress {getAnalysisPercent(analysis).toFixed(0)}%
              </AnalysisStatusIndicator>
            )}
            {waiting && (
              <AnalysisStatusIndicator
                variant={AnalysisStatus.WAITING}
                icon={<MoreHorizontal size={14} />}
              >
                Queued
              </AnalysisStatusIndicator>
            )}
            {ended && (
              <AnalysisStatusIndicator
                variant={AnalysisStatus.ENDED}
                icon={'?'}
              >
                Need confirmation
              </AnalysisStatusIndicator>
            )}
            {cancelled && (
              <AnalysisStatusIndicator
                variant={AnalysisStatus.CANCELLED}
                icon={<Minus size={14} />}
              >
                Cancelled
              </AnalysisStatusIndicator>
            )}
            {failed && (
              <AnalysisStatusIndicator
                variant={AnalysisStatus.FAILED}
                icon={'!'}
              >
                Error
              </AnalysisStatusIndicator>
            )}
          </div>

          <div className="analysis-item-date" title="Created at">
            <FormattedDate value={new Date(analysis.creationTime)} />{' '}
            {format(new Date(analysis.creationTime), `H:mm`)}
          </div>
          <div
            className="analysis-item-duration"
            title={formatTime(analysis, distanceInMinutes)}
          >
            <div className="analysis-item-duration-label">
              {waiting ? 'Not started' : formatTime(analysis, formatDistance)}
            </div>
            {displayEstimateValue && (
              <div className="analysis-item-estimated-duration">
                Estimated:{' '}
                {estimatedDurationLabel(
                  analysis.estimatedDuration,
                  analysis.startAnalysisTime,
                  analysis.currentProgress,
                )}
              </div>
            )}
          </div>

          <div
            className="analysis-more analysis-item-actions"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ApiLinkButton analysisId={analysis.id} type={analysis.type} />
            {cancelModal && (
              <AnalysisCancelationModal
                onClose={closeModal}
                onCancel={cancel}
                analysisName={analysis.name}
              />
            )}
            {!isArchived && (
              <AnalsysisShareButton
                analysisId={analysis.id}
                type={analysis.type}
                size="big"
              />
            )}
            {isArchived && (
              <SimpleTooltip content="Restore">
                <Button
                  prepend={<RedoIcon />}
                  onClick={restore}
                  shape="circle"
                  $background="transparent"
                />
              </SimpleTooltip>
            )}
            {canArchive && (
              <SimpleTooltip content="Archive">
                <Button
                  prepend={<DeleteIcon />}
                  onClick={archive}
                  shape="circle"
                  $background="transparent"
                />
              </SimpleTooltip>
            )}
            {!isArchived && (
              <AnalysisCloneButton
                analysisId={analysis.id}
                analysisType={analysis.type}
                userRole={userRole}
                variant="secondary"
                size="big"
                isTransparent
              />
            )}
            {displayCancel && (
              <SimpleTooltip content="Cancel">
                <Button
                  shape="circle"
                  prepend={<XSquare size={14} />}
                  onClick={openCancelModal}
                  $background="transparent"
                />
              </SimpleTooltip>
            )}

            {!isArchived && (
              <div
                style={{ opacity: isFavorite ? 1 : undefined, display: 'flex' }}
              >
                <SimpleTooltip content={'Favorite'}>
                  <Button
                    prepend={
                      isFavorite ? (
                        <StarIcon color="#61ade0" />
                      ) : (
                        <StarOutlineIcon />
                      )
                    }
                    onClick={isFavorite ? unfave : fave}
                    className={isFavorite ? 'Favorite--active' : undefined}
                    shape="circle"
                    $background="transparent"
                  />
                </SimpleTooltip>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default AnalysisListItem;
