import styled from 'styled-components';
import { Box, Button, Text } from 'tombac';

const NewLabel = styled.div`
  width: 41px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2a90d5;
  background: #c6e5f9;
  border-radius: 2px;
  font-weight: bold;
  padding-top: 2px;
  flex-shrink: 0;
  margin-right: 16px;
`;

const Container = styled.div`
  margin: -32px;
  margin-bottom: 32px;
  background: #f6fafe;
  font-family: Noway, sans-serif;
  border-bottom: 1px solid #aad3ee;
  padding: 24px 32px;
  display: flex;
  align-items: center;
`;

export function TableAnnouncement() {
  return (
    <Container>
      <NewLabel>NEW</NewLabel>
      <Box>
        <Text altFont fontWeight="bold" fontSize={12}>
          New report type is available!
        </Text>
        <Text fontSize={12}>
          Analyse inbound and outbound trips through the selected road segment
          using our new <b>Selected link</b> analysis
        </Text>
      </Box>
      <Button
        variant="accent"
        size="s"
        $ml="auto"
        as="a"
        href="https://od.tomtom.com/share/selected-link/1418865220615279853/selected-link?t=c87284d0-b3c1-4d50-9753-8c91afd04f6b"
        target="_blank"
        style={{ flexShrink: 0 }}
      >
        Check demo report
      </Button>
      <Button
        size="s"
        $ml="2sp"
        variant="ghost"
        as="a"
        href="https://support.move.tomtom.com/od-selected-link-analysis/"
        target="_blank"
        style={{ flexShrink: 0 }}
      >
        Learn more
      </Button>
    </Container>
  );
}
