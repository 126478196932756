import { useState } from 'react';

function b64EncodeUnicode(str: string) {
  return btoa(encodeURIComponent(str));
}

export function createRequestFn<Request>(defaultBaseUrl: string, path: string) {
  return (request: Request, baseUrl = defaultBaseUrl): string => {
    const encodedRequest = b64EncodeUnicode(JSON.stringify(request));
    const url = new URL(baseUrl);
    url.pathname = path;
    url.searchParams.set('r', encodedRequest);
    return url.toString();
  };
}

function unicodeDecodeB64(str: string) {
  return decodeURIComponent(atob(str));
}

export function useLinkRequest<Request>() {
  const [request] = useState(() => {
    const params = new URLSearchParams(location.search);
    const r = JSON.parse(unicodeDecodeB64(params.get('r')!)) as Request;
    return r;
  });

  return request;
}

enum ODDataSource {
  /** Default last 2 years */
  All = 'ALL',
  /** Extended date range, limited data */
  Limited = 'LIMITED',
}

enum ODTimeRangeCondition {
  STARTS = 'STARTS',
  WHOLE = 'WHOLE',
  ANY_PART = 'ANY_PART',
}

export interface SelectedLinkRequest {
  name?: string;
  timeRanges?: Array<{
    startTime: string;
    endTime: string;
  }>;
  dateRanges?: Array<{
    startDate: string;
    endDate: string;
    exclusions?: string[];
  }>;
  link?: number[][];
  mapVersion?: string;
  mapType?: string;
  dataSource?: ODDataSource;
  organizations?: Set<string>;
  zoneId?: string;
  daysOfWeek?: string[];
  timeRangeCondition?: ODTimeRangeCondition;
}

export const createSelectedLinkUrl = createRequestFn<SelectedLinkRequest>(
  'https://od.tomtom.com/',
  '/request/selected-link',
);
