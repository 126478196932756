import { ReactNode, useState } from 'react';
import { ChevronUp } from 'react-feather';
import styled from 'styled-components';
import { ChevronDownIcon } from 'tombac-icons';
import { getVariantStyle } from './getVariantStyle';

export type Variant = 'confirm' | 'default' | 'waiting' | 'alert';

interface Props {
  disableBorder?: boolean;
  className?: string;
  children?: ReactNode;
  title?: ReactNode;
  defaultHide?: boolean;
  canHide?: boolean;
  variant?: Variant;
}

const Title = styled.div<{ canHide: boolean; isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Gotham;
  font-size: 14px;
  font-weight: bold;
  padding: 22px 16px;
  padding-bottom: ${({ isOpen }) => (isOpen ? '0px' : '22px')};
  cursor: ${({ canHide }) => (canHide ? 'pointer' : 'default')};
`;

const Content = styled.div<{ hasTitle: boolean }>`
  padding: 22px 16px;
  padding-top: ${({ hasTitle }) => (hasTitle ? '0px' : '22px')};
`;

const AsideSection = ({
  className,
  children,
  title,
  defaultHide = false,
  canHide = true,
}: Props) => {
  const [isOpen, setIsOpen] = useState(!defaultHide);

  const handleClick = () => {
    if (canHide) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className={className}>
      {title && (
        <Title onClick={handleClick} canHide={canHide} isOpen={isOpen}>
          <span>{title}</span>
          {canHide && (isOpen ? <ChevronUp /> : <ChevronDownIcon />)}
        </Title>
      )}
      {isOpen && <Content hasTitle={!!title}>{children}</Content>}
    </div>
  );
};

export default styled(AsideSection)`
  border-bottom: ${(props) =>
    props.disableBorder ? 'none' : '1px solid #e6e6e6'};

  ${({ variant }) => getVariantStyle(variant || 'default')};
`;
