import format from 'date-fns/format';
import { isSameDay } from 'date-fns';
import { DateTemplate } from 'model/TemplateDto';
import { DateRange } from './DateRange';
import { DateRangeDto } from 'model/TimeDto';

const defaultDateFormat = 'DDMMYYYY';

export const parseDate = (s: string, dateFormat: string) => {
  const parsers: { [key: string]: any } = {
    DDMMYYYY: (s: string) => {
      const [dd, mm, yyyy] = s.split('/').map((s) => Number(s));
      return new Date(yyyy, mm - 1, dd);
    },
    MMDDYYYY: (s: string) => {
      const [mm, dd, yyyy] = s.split('/').map((s) => Number(s));
      return new Date(yyyy, mm - 1, dd);
    },
  };

  return parsers[dateFormat || defaultDateFormat](s);
};

export const getCalendarDateFormat = (dateFormat: string) => {
  return {
    DDMMYYYY: 'DD/MM/YYYY',
    MMDDYYYY: 'MM/DD/YYYY',
  }[dateFormat || defaultDateFormat];
};

export const formatDate = (rawDate: Date | string, dateFormat: string) => {
  const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;
  const formatters: {
    [key: string]: (date: Date) => string;
  } = {
    DDMMYYYY: (date: Date) => format(date, `dd/MM/yyyy`),
    MMDDYYYY: (date: Date) => format(date, `MM/dd/yyyy`),
  };

  return formatters[dateFormat || defaultDateFormat](date);
};

export const formatTime = (rawDate: Date | string, clockFormat: string) => {
  const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;
  const formatter = clockFormat === '24' ? 'HH:mm' : 'hh:mm a';
  return format(date, formatter);
};

export const validDate = (s: Date) => {
  if (s.toString() === 'Invalid Date') {
    return false;
  }
  if (s.getFullYear() < 2000) {
    return false;
  }

  return true;
};

export const isSameDateRangeDtoAsDateRange = (
  a?: DateRange,
  b?: DateRangeDto,
) => {
  const aExclusions = getExclusions(a?.exclusions);
  const bExclusions = getExclusions(b?.exclusions);

  return (
    isSameDay(new Date(a?.start), new Date(b?.startDate)) &&
    isSameDay(new Date(a?.end), new Date(b?.endDate)) &&
    aExclusions === bExclusions
  );
};

export const isTemplateMatchingDateRange = (
  template: DateTemplate,
  dateRange: DateRange,
) => {
  if (dateRange === undefined) {
    return false;
  }

  const rangeExclusions = getExclusions(dateRange?.exclusions);
  const templateExclusions = getExclusions(template?.exclusions);
  return (
    isSameDay(new Date(template.fromDate), dateRange.start) &&
    isSameDay(new Date(template.toDate), dateRange.end) &&
    templateExclusions === rangeExclusions
  );
};

const getExclusions = (exclusions: string[] | undefined) =>
  exclusions
    ? [...exclusions].sort((a, b) => a.localeCompare(b)).join(',')
    : '';

export const isSameDateDtoAsTemplate = (
  range: DateRangeDto,
  template: DateTemplate,
) => {
  const rangeExclusions = getExclusions(range.exclusions);
  const templateExclusions = getExclusions(template.exclusions);

  return (
    isSameDay(new Date(template.fromDate), new Date(range.startDate)) &&
    isSameDay(new Date(template.toDate), new Date(range.endDate)) &&
    rangeExclusions === templateExclusions
  );
};
