import { TimeDto } from 'model/TimeDto';
import { AnalysisType, getAnalysisTypeId } from 'model/AnalysisDto';
import { RegionDto } from 'model/RegionDto';
import { fetchApi } from './api';
import { QueryClient, useQuery } from 'react-query';
import { LineString } from '@turf/turf';

export interface AnalysisDraftRequest {
  name?: string;
  timeDefinition?: Partial<TimeDto>;
  type: AnalysisType;
  regions?: RegionDto[];
  link?: LineString;
  organizations?: string[];
  passMatrix?: boolean;
  tripStats?: boolean;
  map?: {
    version: string;
    type: string;
  };
}

export interface AnalysisDraft extends AnalysisDraftRequest {
  id: number;
  creationTime?: Date;
  regionsLink?: string;
  edgeIds?: string[];
}

const draftQueryKey = 'drafts';

const create = async (
  request: AnalysisDraftRequest,
  queryClient: QueryClient,
) => {
  const typeId = getAnalysisTypeId(request.type);

  const draft = await fetchApi<AnalysisDraft>(
    `/rest/analysis/drafts/${typeId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    },
    'json',
  );

  queryClient.setQueryData(draftQueryKey, (drafts: AnalysisDraft[] = []) => {
    return [draft, ...drafts];
  });
  queryClient.cancelQueries(draftQueryKey);
  queryClient.invalidateQueries(draftQueryKey);
};

const deleteDraft = async (id: number, queryClient: QueryClient) => {
  queryClient.setQueryData(draftQueryKey, (drafts: AnalysisDraft[] = []) =>
    drafts.filter((it) => it.id !== id),
  );

  await fetchApi(`/rest/analysis/drafts/${id}`, {
    method: 'DELETE',
  });

  queryClient.cancelQueries(draftQueryKey);
  queryClient.invalidateQueries(draftQueryKey);
};

const get = async (id: number, type: AnalysisType) => {
  const typeId = getAnalysisTypeId(type);
  const draft = await fetchApi<AnalysisDraft>(
    `/rest/analysis/drafts/${typeId}/${id}`,
  );
  if (draft.regionsLink) {
    draft.regions = await fetchApi<RegionDto[]>(draft.regionsLink);
  }

  return draft;
};

const update = async (
  id: number,
  type: AnalysisType,
  request: AnalysisDraftRequest,
  queryClient: QueryClient,
): Promise<AnalysisDraft> => {
  const typeId = getAnalysisTypeId(type);
  const draft = await fetchApi<AnalysisDraft>(
    `/rest/analysis/drafts/${typeId}/${id}`,
    {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    'json',
  );

  queryClient.setQueryData(draftQueryKey, (drafts: AnalysisDraft[] = []) =>
    drafts.map((it) => (it.id === id ? draft : it)),
  );
  queryClient.cancelQueries(draftQueryKey);
  queryClient.invalidateQueries(draftQueryKey);

  return draft;
};

const getAll = async () => {
  const drafts = await fetchApi<AnalysisDraft[]>('/rest/analysis/drafts');
  return drafts;
};

const useDrafts = () => {
  const { data: drafts } = useQuery(draftQueryKey, getAll);

  return drafts;
};

const DraftApi = {
  create,
  get,
  getAll,
  useDrafts,
  deleteDraft,
  update,
};

export default DraftApi;
