import { DateRangeDto } from 'model/TimeDto';
import { differenceInDays } from 'date-fns';
import { dateToDto } from 'logic/time/dateFormat';
import { toCorrectFormat } from '../Date/utils';

export interface DateRange {
  start: Date;
  end: Date;
  exclusions: string[];
}

export const DateRange = (
  start: Date,
  end: Date,
  exclusions?: string[],
): DateRange => ({
  start,
  end,
  exclusions: exclusions ?? [],
});

export const dateRangeFromDto = (dto: DateRangeDto) => ({
  start: new Date(toCorrectFormat(dto.startDate)),
  end: new Date(toCorrectFormat(dto.endDate)),
  exclusions: dto?.exclusions,
});

export const dateRangeToDto = (range: DateRange): DateRangeDto => ({
  startDate: dateToDto(range.start),
  endDate: dateToDto(range.end),
  exclusions: range.exclusions,
});

export const dateRangeFromLimit = ({
  from,
  to,
}: {
  from?: Date;
  to?: Date;
}) => ({
  start: from,
  end: to,
  exclusions: [],
});

export const clampDate = (date: Date, limits: DateRange) => {
  if (differenceInDays(date, limits.start) < 0) {
    return limits.start;
  }
  if (differenceInDays(date, limits.end) > 0) {
    return limits.end;
  }
  return date;
};

/**
 * Constrain range to lie between limits
 */
export const clamp = (range: DateRange, limits: DateRange) => {
  const start = clampDate(range.start, limits);
  const end = clampDate(range.end, limits);
  // If range is within limits return the same reference to object
  if (range.start === start && range.end === end) {
    return range;
  }

  return DateRange(start, end);
};

export const durationInDays = (range: DateRange) => {
  return differenceInDays(range.end, range.start);
};

export const withEnd = (range: DateRange, end: Date) => ({
  ...range,
  start: range.start,
  end,
});
export const withStart = (range: DateRange, start: Date) => ({
  ...range,
  end: range.end,
  start,
});

export const isWithin = (range: DateRange, limit: DateRange) => {
  return (
    range.start >= limit.start &&
    range.start <= limit.end &&
    range.end >= limit.start &&
    range.end <= limit.end
  );
};
