import { TransparentButton } from 'components/UI/FormUI';
import { SaveIcon } from 'tombac-icons';

interface Props {
  disabled: boolean;
  onClick: VoidFunction;
}

export const TemplateButton = ({ disabled, onClick }: Props) => {
  return (
    <TransparentButton
      prepend={<SaveIcon />}
      size="s"
      variant="flat"
      tiny
      disabled={disabled}
      onClick={onClick}
    />
  );
};
