import { LimitsWithUsage } from 'api/LimitsApi';
import { eachDayOfInterval, getDaysInYear } from 'date-fns';
import { Requirement } from 'hooks/useValidation';
import { dateToDto } from 'logic/time/dateFormat';
import { AnalysisType } from 'model/AnalysisDto';
import { DayOfWeek } from 'model/DayOfWeek';
import { RegionDto } from 'model/RegionDto';
import { DateRangeDto } from 'model/TimeDto';
import { count } from 'ramda';
import { MenuState } from 'reducers/menuReducer';
import { eachDayFromExcludedDays } from '../Date/utils';
import {
  DateRange,
  dateRangeFromDto,
  dateRangeFromLimit,
  isWithin,
} from '../DateRanges/DateRange';
import { isSameDateRangeDtoAsDateRange } from '../DateRanges/DateUtils';
import { isDateInActiveDays } from '../dateRangeUtils';
import { calculateArea } from '../RegionSelection/areaValidation';

const countActiveDays = (dateRange: DateRange, activeDays: DayOfWeek[]) => {
  return count((d: Date) => isDateInActiveDays(d, activeDays))(
    eachDayOfInterval(dateRange),
  );
};

export const nameRequirements: Requirement<{ menu: MenuState }>[] = [
  {
    label: 'You must specify report name',
    check: ({ menu }) => menu.name.length === 0,
  },
  {
    label: 'Name cannot be longer than 100 characters',
    check: ({ menu }) => menu.name.length > 100,
  },
];

export const datesRequirements: Requirement<{
  menu: MenuState;
  limits?: LimitsWithUsage;
}>[] = [
  {
    label: 'Add date to go to the next step',
    check: ({ menu }) => menu.dateRanges.length === 0,
  },
  {
    label: 'You must specify at least one day of the week',
    check: ({ menu }) => menu.daysOfWeek.length === 0,
  },
  {
    label: 'Some of selected dates are outside available dates',
    check: ({ menu, limits }) => {
      if (!limits) return false;

      return menu.dateRanges.some(
        (it) =>
          !isWithin(
            dateRangeFromDto(it),
            dateRangeFromLimit(limits.limits.dateRange),
          ),
      );
    },
  },
  {
    label: (state) => {
      const dateRangesExceedingLimit = dateRangesExceedingOneYear(
        state.menu.dateRanges,
        state.menu.daysOfWeek,
      )[0];
      return `Date range cannot have more than ${maxDaysInDateRange(
        dateRangesExceedingLimit,
      )} days`;
    },
    check: (state) => {
      return (
        state.menu.dateRanges.length > 0 &&
        dateRangesExceedingOneYear(state.menu.dateRanges, state.menu.daysOfWeek)
          .length > 0
      );
    },
  },
];

const dateRangesExceedingOneYear = (
  dateRanges: DateRangeDto[],
  daysOfWeek: DayOfWeek[],
) =>
  dateRanges.filter((dateRange) => {
    const days = eachDayOfInterval({
      start: new Date(dateRange.startDate),
      end: new Date(dateRange.endDate),
    }).filter(
      (it) =>
        daysOfWeek.includes(Object.values(DayOfWeek)[it.getDay()]) == true,
    );
    return days.length > maxDaysInDateRange(dateRange);
  });

const maxDaysInDateRange = (dateRange: DateRangeDto) =>
  getDaysInYear(new Date(dateRange.startDate));

export const timesRequirements: Requirement<{ menu: MenuState }>[] = [
  {
    label: 'Add times to go to the next step',
    check: ({ menu }) => menu.timeRanges.length === 0,
  },
  {
    label: 'Time condition must be specified',
    check: ({ menu }) => menu.timeRangeCondition === undefined,
  },
];

export const regionsRequirements: Requirement<{ menu: MenuState }>[] = [
  {
    label: 'Add regions to go to the next step',
    check: ({ menu }) =>
      menu.type === AnalysisType.FlowMatrix && menu.regions.length === 0,
  },
  {
    label: 'Select a link to go the next step',
    check: ({ menu }) =>
      menu.type === AnalysisType.SelectedLink && menu.links.length === 0,
  },
];

export const regionSelectionRequirements: Requirement<{
  regions: RegionDto[];
  limits: LimitsWithUsage;
  zoneId?: string;
  coverage?: any;
}>[] = [
  {
    label: 'Regions number exceeded',
    check: (suspect) =>
      suspect.regions.length > suspect.limits?.limits?.maxRegionCount,
  },
  {
    label: 'Regions area exceeded',
    check: (suspect) =>
      calculateArea(suspect.regions) > suspect.limits?.limits?.maxAreaSize,
  },
  {
    label: 'Regions area exceeded',
    check: (suspect) =>
      calculateArea(suspect.regions) >
      suspect.limits?.limits?.totalAreaInSquareKm,
  },
  {
    label: 'Create regions to add it to report',
    check: (suspect) => suspect.regions.length === 0,
  },
  {
    label: 'Timezone must be specified',
    check: (suspect) => !(suspect.zoneId?.length > 0),
  },
  {
    label: 'Some regions are invalid',
    check: (suspect) =>
      suspect.regions.some(
        (region) => region.properties.validationResult.status === 'INVALID',
      ),
  },
  {
    label: 'Road coverage limit exceeded',
    check: ({ limits, coverage }) => {
      const hasLimit = Number.isInteger(limits?.limits?.totalRoadNetwork);
      const remaining = hasLimit
        ? Math.max(
            0,
            limits.limits.totalRoadNetwork -
              limits.usage.totalRoadNetwork -
              (coverage ?? 0),
          )
        : undefined;

      return hasLimit ? remaining === 0 : false;
    },
  },
];

export const dateRangeModalRequirements: Requirement<{
  menu: MenuState;
  dateRange: DateRange;
  editingDateRangeIndex?: number;
  activeDays?: DayOfWeek[];
}>[] = [
  {
    label: 'You already selected this range',
    check: (suspect) => {
      const { dateRange, activeDays } = suspect;
      if (dateRange?.start && dateRange?.end) {
        const exclusionsForDays = eachDayFromExcludedDays(
          dateRange,
          activeDays || Object.values(DayOfWeek),
        ).map((d) => dateToDto(d));

        const dateRangeWithAllExclusions: DateRange = {
          ...dateRange,
          exclusions: [...dateRange.exclusions, ...exclusionsForDays],
        };

        const sameRangeIndex = suspect.menu.dateRanges.findIndex((dateRange) =>
          isSameDateRangeDtoAsDateRange(dateRangeWithAllExclusions, dateRange),
        );
        if (suspect.editingDateRangeIndex !== undefined) {
          return (
            sameRangeIndex !== -1 &&
            sameRangeIndex !== suspect.editingDateRangeIndex
          );
        }
        return sameRangeIndex !== -1;
      }
      return false;
    },
  },
  {
    label: 'Date range cannot have more than 366 days',
    check: ({ dateRange }) =>
      dateRange?.start && dateRange?.end
        ? eachDayOfInterval(dateRange).length > 365
        : false,
  },
  {
    label: 'Start date and end date cannot be empty',
    check: ({ dateRange }) => !dateRange?.start || !dateRange?.end,
  },
  {
    label: 'At least one day of the week must be selected',
    check: (suspect) => suspect?.activeDays?.length === 0,
  },
  {
    label: 'You cannot exclude all days from the date range',
    check: (suspect) => {
      if (
        suspect.dateRange?.start &&
        suspect.dateRange?.end &&
        suspect.activeDays
      ) {
        const numberOfDays = countActiveDays(
          suspect.dateRange,
          suspect.activeDays,
        );
        return numberOfDays === suspect.dateRange.exclusions.length;
      }
      return false;
    },
  },
];
