/* eslint-disable prefer-rest-params */
import { ListResult, ReadableResult } from 'logic/ReadableResult';
import { CreateCache } from 'logic/storage/Cache';
import ResultCache from 'logic/storage/resultCache';
import { DateRangeDto, TimeRange } from 'model/TimeDto';
import qs from 'qs';
import {
  Analysis,
  AnalysisDto,
  AnalysisDurationEstimation,
  AnalysisInfo,
  AnalysisPage,
  AnalysisResult,
  AnalysisSummariesDto,
  AnalysisType,
} from '../model/AnalysisDto';
import {
  AnalysisRequest,
  EstimateDurationRequest,
} from '../model/AnalysisParameters';
import { RegionDto } from '../model/RegionDto';
import { handleRegionVersions } from './AnalysisApiUtils';
import { fetchApi } from './api';

const AnalysisURLs = {
  [AnalysisType.FlowMatrix]: '/rest/analysis/flowmatrix',
  [AnalysisType.SelectedLink]: '/rest/analysis/selected-link',
};

export interface RequestListParams {
  filter: string;
  owner?: string;
  page: number;
  pageSize: number;
  ownedOnly: boolean;
  favoriteOnly: boolean;
  archivedOnly: boolean;
  statusFilterSet?: Set<string>;
}

const addIndex = (region: any, i: number) => {
  region.properties.i = i;
  return region as RegionDto;
};

function post(analysisType: AnalysisType, requestBody: AnalysisRequest) {
  const url = AnalysisURLs[analysisType];
  return fetchApi<AnalysisInfo>(
    url,
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    'json',
  );
}

const regionCache = CreateCache<number, AnalysisDto[]>(
  (k: number) => String(k),
  5,
);

async function get(
  analysisId: number,
  type: AnalysisType,
  shareKey?: string,
): Promise<Analysis> {
  const urlBase = {
    [AnalysisType.FlowMatrix]: 'rest/analysis/flowmatrix',
    [AnalysisType.SelectedLink]: 'rest/analysis/selected-link',
  }[type];

  const url = shareKey
    ? `/public/${urlBase}/${analysisId}/shared?getResult=false&t=${shareKey}`
    : `/${urlBase}/${analysisId}?getResult=false`;

  const response: AnalysisDto = await fetchApi<AnalysisDto>(url);

  let regions;
  if (regionCache.has(analysisId)) {
    regions = regionCache.get(analysisId);
  } else {
    if (type === AnalysisType.FlowMatrix) {
      regions = await fetchApi<any[]>(response.regionsLink);
    }
    if (type === AnalysisType.SelectedLink) {
      regions = [
        {
          type: 'Feature',
          geometry: (response as any).analysisInfo.link,
          properties: {
            edgeIds: (response as any).analysisInfo.edgeIds,
          },
        },
      ];
    }
    regions = handleRegionVersions(regions);
    regions = regions.map(addIndex);

    regionCache.add(analysisId, regions);
  }

  if (response === null) {
    throw new Error('Failed to parse response');
  }

  const analysis: Analysis = {
    info: response.analysisInfo,
    results: response.results,
    regions,
  };

  return analysis;
}

async function cancel(analysisId: number) {
  await fetchApi(`/rest/analysis/${analysisId}/cancel`, {
    method: 'POST',
  });

  return;
}

async function getList({
  filter,
  owner,
  page,
  pageSize,
  ownedOnly,
  favoriteOnly,
  archivedOnly,
  statusFilterSet,
}: RequestListParams) {
  const path = '/rest/analysis';
  const queryObject: any = {
    name: filter,
    owner,
    page,
    limit: pageSize,
    ownedOnly,
    favoriteOnly,
    archivedOnly,
  };

  if (statusFilterSet && statusFilterSet.size) {
    queryObject.statuses = JSON.stringify(statusFilterSet).replace(
      /\[|"|]/g,
      '',
    );
  }

  const query = qs.stringify(queryObject);
  const analysisList: AnalysisPage = await fetchApi(path + '?' + query);

  return analysisList;
}

const fave = async (analysisId: number) => {
  const url = `/rest/analysis/${analysisId}/favorite`;
  await fetchApi(url, {
    method: 'POST',
  });
};

const unfave = async (analysisId: number) => {
  const url = `/rest/analysis/${analysisId}/favorite`;
  await fetchApi(url, {
    method: 'DELETE',
  });
};

const share = async (analysisId: number) => {
  const token = await fetchApi<string>(
    `/rest/analysis/${analysisId}/shared/token`,
    {
      method: 'POST',
    },
    'text',
  );
  return token;
};

const getSharedToken = async (analysisId: number) => {
  const token = await fetchApi<string>(
    `/rest/analysis/${analysisId}/shared/token`,
    {
      method: 'GET',
    },
    'text',
  );
  return token;
};

const deleteSharedToken = async (analysisId: number) => {
  const token = await fetchApi(
    `/rest/analysis/${analysisId}/shared/token`,
    {
      method: 'DELETE',
    },
    'text',
  );
  return token;
};

const rename = async (analysisId: number, name: string): Promise<string> => {
  const response = await fetchApi<{ name: string }>(
    `/rest/analysis/${analysisId}/name`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ name }),
    },
    'json',
  );
  return response.name;
};

const renameRegions = async (analysisId: number, names: string[]) => {
  await fetchApi(
    `/rest/analysis/${analysisId}/regions/names`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ names }),
    },
    'none',
  );
};

const accept = async (analysisId: number) =>
  await fetchApi(`/rest/analysis/${analysisId}/accept`, { method: 'POST' });

const reject = async (analysisId: number) =>
  await fetchApi(`/rest/analysis/${analysisId}/reject`, { method: 'POST' });

const estimateDuration = async (
  estimateDurationRequest: EstimateDurationRequest,
): Promise<number> => {
  const response = (await fetchApi(
    `/rest/analysis/estimateduration`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(estimateDurationRequest),
    },
    'json',
  )) as AnalysisDurationEstimation;
  return response.durationInSeconds;
};

/**
 * Partial in this context means the we specify O/D/V indices
 */
const getPartialResult = async (
  link: string,
  {
    origins,
    destinations,
    vias,
  }: { origins: number[]; destinations: number[]; vias: number[] },
) => {
  const res = await fetchApi<AnalysisResult>(
    link,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        origins,
        destinations,
        vias,
      }),
    },
    'json',
  );

  return res;
};

const fullResultCache = ResultCache.create();
async function getFullResult(
  analysis: Analysis,
  dateRange: DateRangeDto,
  timeRange: TimeRange,
): Promise<ReadableResult> {
  const cacheHit = fullResultCache.get({
    analysisId: analysis.info.id,
    dateRange,
    timeRange,
  });
  if (cacheHit) {
    return cacheHit;
  }

  const resultDto = analysis.results.find((r) => {
    return (
      JSON.stringify(r.dateRange) === JSON.stringify(dateRange) &&
      JSON.stringify(r.timeRange) === JSON.stringify(timeRange)
    );
  });

  if (resultDto === undefined) {
    throw new Error('Failed to fetch result');
  }

  const res = await fetchApi<AnalysisResult>(resultDto.tripsWholeResultLink);
  const result = ListResult(res.links as any, dateRange, timeRange);

  if (result === null) {
    throw new Error('Failed to get result');
  }

  fullResultCache.add(
    { analysisId: analysis.info.id, dateRange, timeRange },
    result,
  );

  return result;
}

/**
 * for api v1 only downloads full result (per date/time range)
 * for api v2 downloads partial depending on the result size
 */
async function getResult(
  analysis: Analysis,
  dateRange: DateRangeDto,
  timeRange: TimeRange,
  {
    origins,
    destinations,
    vias,
  }: { origins: number[]; destinations: number[]; vias: number[] },
): Promise<ReadableResult> {
  const resultDto = analysis.results.find(
    (r) =>
      JSON.stringify(r.dateRange) === JSON.stringify(dateRange) &&
      JSON.stringify(r.timeRange) === JSON.stringify(timeRange),
  );

  if (resultDto === undefined) {
    throw new Error('Failed to fetch result');
  }

  const MAX_FULL_RESULT_SIZE_MB = 10;
  const shouldGetFullResult =
    resultDto.tripsResultFileSize < MAX_FULL_RESULT_SIZE_MB;
  if (shouldGetFullResult) {
    return getFullResult(analysis, dateRange, timeRange);
  } else {
    const rawResult = await getPartialResult(resultDto.tripsPartialResultLink, {
      origins,
      destinations,
      vias,
    });

    return ListResult(rawResult.links, dateRange, timeRange);
  }
}

export enum HistogramType {
  Time = 'Start hour',
  Duration = 'Duration',
  Length = 'Length',
}

export enum SelectedLinkResultFormat {
  PROTOBUF = 'PROTOBUF',
  JSON = 'JSON',
  CSV = 'CSV',
  SHAPEFILE = 'SHAPEFILE',
}

export const histogramTypeToDto = (type: HistogramType) =>
  ({
    [HistogramType.Time]: 'HOURS_OF_DAY',
    [HistogramType.Duration]: 'DURATIONS',
    [HistogramType.Length]: 'LENGTHS',
  }[type]);

export interface HistogramApiResponse {
  bucketInterval: number;
  histogram: Map<number, number>;
  unit: string;
  overflowBucket?: number;
}

const convertObjectToMap = (histogram: HistogramApiResponse) => {
  const map = new Map<number, number>();
  for (const key of Object.keys(histogram.histogram)) {
    map.set(parseInt(key, 10), histogram.histogram[key]);
  }
  return map;
};

const getHistogram = async (
  analysis: Analysis,
  histogramType: HistogramType,
  dateRange: DateRangeDto,
  timeRange: TimeRange,
  {
    origins,
    destinations,
    vias,
  }: { origins: number[]; destinations: number[]; vias: number[] },
) => {
  const resultDto = analysis.results.find(
    (r) =>
      JSON.stringify(r.dateRange) === JSON.stringify(dateRange) &&
      JSON.stringify(r.timeRange) === JSON.stringify(timeRange),
  );

  const link = {
    [HistogramType.Time]: resultDto.hoursOfDayHistogramPartialResultLink,
    [HistogramType.Length]: resultDto.lengthsHistogramPartialResultLink,
    [HistogramType.Duration]: resultDto.durationsHistogramPartialResultLink,
  }[histogramType];

  const histogram = await fetchApi<HistogramApiResponse>(
    link,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        origins,
        destinations,
        vias,
      }),
    },
    'json',
  );
  return { ...histogram, histogram: convertObjectToMap(histogram) };
};

const archive = async (id: number) => {
  return await fetchApi(`/rest/analysis/${id}/archive`, {
    method: 'POST',
  });
};

const restore = async (id: number) => {
  return await fetchApi(`/rest/analysis/${id}/restore`, {
    method: 'POST',
  });
};

const getDebugTraces = async (id: number) => {
  const { traces } = await fetchApi(
    `/rest/analysis/flowmatrix/${id}/result/traces?dateRange=0&timeRange=0`,
  );

  return traces;
};

const getSummary = async (id: number): Promise<AnalysisSummariesDto> => {
  return await fetchApi(`/rest/analysis/${id}/summaries`);
};

const AnalysisApi = {
  share,
  getSharedToken,
  deleteSharedToken,
  post,
  get,
  cancel,
  getList,
  getResult,
  getFullResult,
  fave,
  unfave,
  rename,
  renameRegions,
  accept,
  reject,
  getHistogram,
  estimateDuration,
  archive,
  restore,
  getDebugTraces,
  getSummary,
};

export { AnalysisApi };
export default AnalysisApi;
