import LimitsApi from 'api/LimitsApi';
import { PassMatrixTooltip } from 'components/UI/PassMatrixTooltip';
import { HStack } from 'components/UI/Stack/Stack';
import { useValidation } from 'hooks/useValidation';
import analysisTypeName from 'logic/analysis/analysisTypeName';
import { AnalysisType } from 'model/AnalysisDto';
import { MapMatchOption } from 'model/AnalysisParameters';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuState, useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  Heading,
  Input,
  Label,
  Modal,
  RadioGroup,
  TooltipOnHover,
} from 'tombac';
import { ChevronDownIcon, ChevronUpIcon } from 'tombac-icons';
import { SimpleSelect } from 'tombac/dist/components/Form/SimpleSelect';
import { useIsAdmin } from 'user';
import {
  ModalActions,
  ModalContent,
  ModalForm,
  ModalHeader,
  Text,
  ValidationMessagesSection,
} from '../UI/FormUI';
import { DataSourceSelect } from './DataSourceSelect';
import Organizations from './Organizations/Organizations';
import { VehicleTypesRadio } from './VehicleTypesRadio';
import odSelectedImg from './origin-destination--selected.svg';
import odImg from './origin-destination.svg';
import { nameRequirements } from './Requirements/requirements';
import selectedLinkSelectedImg from './selected-link--selected.svg';
import selectedLinkImg from './selected-link.svg';

const BigCardContainer = styled.div<{ active?: boolean; margin?: string }>`
  height: 160px;
  width: 100%;
  padding: 16px;
  display: grid;
  grid-template-columns: 140px auto;
  column-gap: 0px;
  border-radius: 1px;
  cursor: pointer;
  border: ${(p) => (p.active ? 'solid 1px #8dc3eb' : 'solid 1.1px #e5e5e5')};
  background: ${(p) => (p.active ? '#f6fafe' : '#ffffff')};
  color: ${(p) => (p.active ? '#2a90d5' : undefined)};
  margin: ${(p) => p.margin};
`;

function CardItem({
  img,
  label,
  description,
  active,
  margin,
  onClick,
}: {
  img?: any;
  label: any;
  description: string;
  active: boolean;
  margin?: string;
  onClick?: () => void;
}) {
  return (
    <BigCardContainer active={active} margin={margin} onClick={onClick}>
      <img src={img} style={{ width: '100%', alignSelf: 'center' }} />
      <div>
        <Text
          display="flex"
          style={{ alignItems: 'center' }}
          fontSize="14px"
          marginLeft="8px"
          marginRight="8px"
          marginTop="12px"
          color={active ? '#2a90d5' : '#000000'}
          fontFamily="Gotham"
          fontWeight="600"
        >
          {label}
        </Text>
        <Text
          display="block"
          marginLeft="8px"
          marginRight="8px"
          marginTop="6px"
          lineHeight="1.5"
          color={active ? '#2a90d5' : undefined}
        >
          {description}
        </Text>
      </div>
    </BigCardContainer>
  );
}

const NewBadge = styled.div<{ active?: boolean }>`
  height: 18px;
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 4px;
  background: ${(p) => (p.active ? '#2a90d5' : '#000')};
  font-family: Gotham;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  margin-left: 4px;
`;

const passMatrixOptions = [
  {
    label: 'Add to external region',
    value: false,
  },
  {
    label: 'Trim to defined region',
    value: true,
  },
];
function PassMatrixSelector({ passMatrix, setPassMatrix }) {
  return (
    <FormGroup label="Trips outside regions">
      <Box>
        <RadioGroup
          value={passMatrixOptions.find((it) => it.value === passMatrix)}
          getOptionLabel={(e) => (
            <TooltipOnHover
              content={<PassMatrixTooltip passMatrix={e.value} />}
              placement="top"
            >
              {e.label}
            </TooltipOnHover>
          )}
          onChange={(e) => setPassMatrix(e.value)}
          options={passMatrixOptions}
          variant="horizontal"
        />
      </Box>
    </FormGroup>
  );
}

function Collapsable({ children }) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Box $mt="18px">
      <Text
        onClick={() => setCollapsed(!collapsed)}
        fontFamily="Gotham"
        fontSize="12px"
        fontWeight="600"
        color="#000"
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Advanced Settings{' '}
        <Box $ml="8px">
          {collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </Box>
      </Text>
      {collapsed ? null : <Box $pt="20px">{children}</Box>}
    </Box>
  );
}

export const NewReportModal = ({
  isOpen,
  onClose,
  action,
}: {
  isOpen: boolean;
  onClose: (action: 'cancel' | 'submit') => any;
  action: 'new' | 'edit' | 'clone';
}) => {
  const [menu, setMenu] = useMenu();
  const nameInputRef = useRef(undefined);
  const [name, setName] = useState(menu.name);
  const [type, setType] = useState(menu.type);
  const [passMatrix, setPassMatrix] = useState(menu.passMatrix);
  const [organizations, setOrganizations] = useState(menu.organizations);
  const limits = LimitsApi.use();
  const history = useHistory();
  const isAdmin = useIsAdmin();
  const validation = useValidation(
    nameRequirements,
    { menu: { name, passMatrix, organizations } as MenuState },
    () => {},
  );

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const { title, submitButtonText } = {
    title: {
      new: 'Create new report',
      clone: 'Clone report',
      edit: 'Report settings',
    }[action],
    submitButtonText: {
      new: 'Create',
      clone: 'Create',
      edit: 'Update',
    }[action],
  };

  const updateReportSettings = () => {
    setMenu({ name, passMatrix, organizations, type });
  };

  if (!isOpen) {
    return null;
  }

  const showAdvancedOptions =
    isAdmin ||
    limits?.limits?.limitedDataSourcesEnabled ||
    limits?.limits?.excludeMobileSourcesEnabled ||
    limits?.limits?.passengerAndFleetDivisionEnabled;

  const isFlowMatrix = type === AnalysisType.FlowMatrix;

  return (
    <Modal isOpen onRequestClose={() => onClose('cancel')}>
      <ModalContent style={{ maxWidth: '750px' }}>
        <ModalHeader>
          <Heading
            fontWeight="bold"
            fontFamily="Gotham"
            level={2}
            fontSize="16"
          >
            {title}
          </Heading>
        </ModalHeader>

        <ModalForm>
          <HStack>
            <FormGroup
              label="Choose name"
              $marginBottom="28u"
              $width="409u"
              error={validation?.messages?.length > 0}
            >
              <Input
                placeholder="Report name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                width="500px"
                ref={nameInputRef}
                invalid={validation?.messages?.length > 0}
                data-test="new-report-name-input"
              />
            </FormGroup>
          </HStack>

          <HStack>
            <Label>Report type</Label>
          </HStack>
          <HStack>
            <CardItem
              img={type === AnalysisType.FlowMatrix ? odSelectedImg : odImg}
              label={analysisTypeName(AnalysisType.FlowMatrix)}
              margin="11px 0 16px"
              description="Analyze origin and destination of trips which started, passed through or ended within your defined regions."
              active={type === AnalysisType.FlowMatrix}
              onClick={() => setType(AnalysisType.FlowMatrix)}
            />
            <CardItem
              img={
                type === AnalysisType.SelectedLink
                  ? selectedLinkSelectedImg
                  : selectedLinkImg
              }
              label={
                <>
                  {analysisTypeName(AnalysisType.SelectedLink)}{' '}
                  <NewBadge active={type === AnalysisType.SelectedLink}>
                    NEW
                  </NewBadge>
                </>
              }
              description="Analyze inbound and outbound trips through the selected road segment."
              margin="11px 0 16px"
              active={type === AnalysisType.SelectedLink}
              onClick={() => setType(AnalysisType.SelectedLink)}
            />
          </HStack>

          {isFlowMatrix && (
            <PassMatrixSelector
              passMatrix={passMatrix}
              setPassMatrix={setPassMatrix}
            />
          )}

          {showAdvancedOptions && (
            <Collapsable>
              <Box flexWrap="wrap" display="flex">
                {limits?.limits?.limitedDataSourcesEnabled && (
                  <Box $flexGrow="1">
                    <DataSourceSelect />
                  </Box>
                )}
                {isAdmin && (
                  <>
                    <Box $flexGrow="1">
                      <FormGroup label="Organizations">
                        <Organizations
                          organizations={organizations}
                          setOrganizations={setOrganizations}
                        />
                      </FormGroup>
                    </Box>
                    <Box $flexGrow="1">
                      <FormGroup label="Map Maptching">
                        <SimpleSelect
                          value={menu.mapMatchOption}
                          onChange={(value) =>
                            setMenu({ mapMatchOption: value })
                          }
                          options={[
                            { label: 'Auto', value: MapMatchOption.Auto },
                            { label: 'Enabled', value: MapMatchOption.Enabled },
                            {
                              label: 'Disabled',
                              value: MapMatchOption.Disabled,
                            },
                          ]}
                        />
                      </FormGroup>
                    </Box>
                    {isFlowMatrix && (
                      <Box $flexGrow="1">
                        <FormGroup label="Trip stats">
                          <Checkbox
                            $paddingLeft="8px"
                            checked={menu.tripStats}
                            variant="toggle"
                            onChange={(e) =>
                              setMenu({ tripStats: e.target.checked })
                            }
                          />
                        </FormGroup>
                      </Box>
                    )}
                  </>
                )}
                {limits.limits.passengerAndFleetDivisionEnabled && (
                  <Box $flexGrow="1">
                    <FormGroup label="Vehicle types">
                      <VehicleTypesRadio />
                    </FormGroup>
                  </Box>
                )}
                {(limits?.limits?.excludeMobileSourcesEnabled || isAdmin) && (
                  <Box $flexGrow="1">
                    <FormGroup label="Exclude mobile data">
                      <Checkbox
                        checked={menu.excludeMobile}
                        variant="toggle"
                        onChange={(e) =>
                          setMenu({ excludeMobile: e.target.checked })
                        }
                      />
                    </FormGroup>
                  </Box>
                )}
              </Box>
            </Collapsable>
          )}
        </ModalForm>

        <ValidationMessagesSection bottom="0px">
          {validation.messages}
        </ValidationMessagesSection>

        <ModalActions onClick={(e) => e.stopPropagation()}>
          <Button variant="flat" $mr="2sp" onClick={() => onClose('cancel')}>
            Cancel
          </Button>

          <Button
            onClick={() => {
              if (validation.canSubmit()) {
                updateReportSettings();
                onClose('submit');
                history.push('/new/dates');
              }
            }}
            variant="primary"
            data-test="modal-create-button"
          >
            {submitButtonText}
          </Button>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
};
