import { DayOfWeek } from './DayOfWeek';

export interface DateRangeDto {
  startDate: Date | string;
  endDate: Date | string;
  exclusions?: string[] | undefined;
}

export interface TimeRange {
  startTime: string;
  endTime: string;
}

export enum TimeRangeCondition {
  STARTS = 'STARTS',
  WHOLE = 'WHOLE',
  ANY_PART = 'ANY_PART',
}

export interface TimeDto {
  zoneId: string;
  dateRanges: DateRangeDto[];
  timeRanges: TimeRange[];
  daysOfWeek: DayOfWeek[];
  timeRangeCondition?: TimeRangeCondition;
}
