import { useIsAdmin } from 'user';
import OrganizationsParameters from './OrganizationsParameters';

const Organizations = ({
  organizations,
  setOrganizations,
}: {
  organizations: Set<string>;
  setOrganizations: (set: any) => void;
}) => {
  const isAdmin = useIsAdmin();

  return (
    isAdmin && (
      <OrganizationsParameters
        organizations={organizations}
        setOrganizations={setOrganizations}
      />
    )
  );
};

export default Organizations;
