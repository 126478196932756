import { useState } from 'react';
import { Button, Checkbox, FormGroup, Modal } from 'tombac';
import { EditIcon } from 'tombac-icons';
import { Spacer, Text, TransparentButton } from '../../UI/FormUI';
import {
  AllOrganizations,
  FleetOrganizations,
  ODOrganisations,
  PassengerOrganizations,
} from '../configs/organizations';
import './OrganizationsParameters.css';

const equals = (set: Set<any>, list: any[]) =>
  list.every((org) => set.has(org)) && list.length === set.size;

const OrganizationsParameters = ({
  organizations,
  setOrganizations,
}: {
  organizations: Set<string>;
  setOrganizations: (set: any) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOrgSelect = (org: string) => {
    const newSet = new Set(organizations);
    if (newSet.has(org)) {
      newSet.delete(org);
    } else {
      newSet.add(org);
    }
    setOrganizations(newSet);
  };

  const hasAllOrganizations = (orgs: any[]) =>
    orgs.every((org) => organizations.has(org));

  const getOrgsString = () => {
    if (equals(organizations, FleetOrganizations)) {
      return 'Fleet';
    } else if (equals(organizations, PassengerOrganizations)) {
      return 'Passengers';
    } else if (equals(organizations, ODOrganisations)) {
      return 'Default';
    } else if (AllOrganizations.length === organizations.size) {
      return 'All';
    } else {
      return Array.from(organizations).join(', ');
    }
  };

  const getSelectDeselectLabel = (orgs: string[]) =>
    !hasAllOrganizations(orgs) ? 'Select ' : 'Deselect ';

  const toggleOrgs = (orgs: string[]) => {
    if (!hasAllOrganizations(orgs)) {
      setOrganizations(new Set([...organizations, ...orgs]));
    } else {
      setOrganizations(
        new Set([...organizations].filter((org) => !orgs.includes(org))),
      );
    }
  };

  const isSelected = (org: string) => organizations.has(org);

  return (
    <div className="OrganizationsParameters">
      {isOpen && (
        <Modal isOpen onRequestClose={() => setIsOpen(false)}>
          <div className="OrganisationParameters__Modal-content">
            <div className="header">
              <h1 style={{ marginTop: 0 }}>Organizations</h1>
              <FormGroup label="Filters" $mt="13u">
                <Checkbox
                  label="Default"
                  onChange={() => toggleOrgs(ODOrganisations)}
                  checked={hasAllOrganizations(ODOrganisations)}
                  $mr="40u"
                />
                <Checkbox
                  label="Fleet"
                  onChange={() => toggleOrgs(FleetOrganizations)}
                  checked={hasAllOrganizations(FleetOrganizations)}
                  $mr="40u"
                />
                <Checkbox
                  label="Passengers"
                  onChange={() => toggleOrgs(PassengerOrganizations)}
                  checked={hasAllOrganizations(PassengerOrganizations)}
                />
              </FormGroup>
            </div>
            <div className="body">
              <Button
                className="OrganizationsParameters__toggle-all-button"
                onClick={() => toggleOrgs(AllOrganizations)}
              >
                {getSelectDeselectLabel(AllOrganizations)}
                all
              </Button>
              <div className="OrganizationsParameters__list">
                {AllOrganizations.map((org) => (
                  <div className="OrganizationsParameters__item" key={org}>
                    <Checkbox
                      checked={isSelected(org)}
                      label={org}
                      onChange={() => handleOrgSelect(org)}
                    />
                  </div>
                ))}
              </div>
              <div className="OrganizationsParameters__actions">
                <TransparentButton
                  onClick={() => setIsOpen(false)}
                  variant="flat"
                >
                  Cancel
                </TransparentButton>
                <Spacer width={2} />
                <Button onClick={() => setIsOpen(false)} variant="accent">
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Text color="#000000" marginRight="8px">
        {getOrgsString()}
      </Text>
      <TransparentButton
        prepend={<EditIcon />}
        size="xs"
        variant="flat"
        tiny
        onClick={() => setIsOpen(true)}
      />
    </div>
  );
};

export default OrganizationsParameters;
